import React, { useState, useEffect, ChangeEvent, FC, useRef } from "react";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { ReactComponent as TriangleArrowDownIcon } from "@/assets/icons/svg/arrows/triangleArrow/triangleArrowDownIcon.svg";
import { ReactComponent as TriangleArrowUpIcon } from "@/assets/icons/svg/arrows/triangleArrow/triangleArrowUpIcon.svg";
import { ReactComponent as SearchMagnifierIcon } from "@/assets/icons/svg/searchMagnifierIcon.svg";
import { ReactComponent as CrossIcon } from "@/assets/icons/svg/crossIcon.svg";
import { useTranslation } from "react-i18next";

interface SingleSelectWithSearchProps {
  placeholder: string;
  searchPlaceholder?: string;
  data: any;
  totalCount: number;
  onSelect: (item: any) => void;
  debouncedSearchValueFn: (value: string) => void;
  loadMoreDataFn?: (limit: number) => void;
  isLoading?: boolean;
}

const SingleSelectWithSearchDropdown: FC<SingleSelectWithSearchProps> = ({
  placeholder,
  searchPlaceholder,
  data,
  totalCount,
  onSelect,
  debouncedSearchValueFn,
  loadMoreDataFn,
  isLoading,
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  // Debounce the search input by 300ms
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      debouncedSearchValueFn(searchValue);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  // load more
  const [limit, setLimit] = useState(20);
  const loadMoreData = () => {
    setLimit((prev) => prev + 20);
    loadMoreDataFn && loadMoreDataFn(limit + 20);
  };

  // handle selection
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [selectedName, setSelectedName] = useState<string>("");

  const handleSelect = (item: any) => {
    setSelectedId(item.id);
    setSelectedName(item.name);
    onSelect(item);
    setIsOpen(false);
  };

  // handle click-outside
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative h-[56px] w-full">
      <div
        className={`${isOpen && "border border-secondary"} w-full cursor-pointer rounded-lg bg-white p-3 shadow`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex w-full items-center justify-between">
          <div className="w-full">
            {selectedName ? (
              <p className="text-dark text-sm font-medium">{selectedName}</p>
            ) : (
              <p className="text-sm font-medium text-[#717071B3]">{placeholder}</p>
            )}
          </div>
          <SVGIcon icon={isOpen ? TriangleArrowUpIcon : TriangleArrowDownIcon} fill="#717071" width="24" height="24" />
        </div>
      </div>

      {isOpen && (
        <div
          ref={dropdownRef}
          className="animate-fade-in-down absolute left-0 top-[100%] z-20 w-full rounded-lg bg-white p-3 shadow"
        >
          <div className="flex w-full items-center gap-3 border-b-[1px] border-[#BDBDBD80] px-3 pb-3 pt-1.5">
            <SVGIcon icon={SearchMagnifierIcon} fill="#717071" width="24" height="24" />
            <input
              placeholder={searchPlaceholder || "Search..."}
              className="w-full text-sm font-medium placeholder:text-[#717071B3] focus:outline-none"
              value={searchValue}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
            />
            <div className="hover-scale-sm cursor-pointer" onClick={() => setIsOpen(false)}>
              <SVGIcon icon={CrossIcon} fill="#BDBDBD" width="24" height="24" />
            </div>
          </div>

          <div className="mt-3 max-h-[140px] overflow-scroll">
            {data.length > 0 ? (
              data.map((item: any) => (
                <div
                  key={item.id}
                  className="flex w-full cursor-pointer items-center gap-3 rounded-lg px-3 py-1.5 hover:bg-[#0306230D]"
                  onClick={() => handleSelect(item)}
                >
                  <p className="text-dark text-sm font-medium">{item.name}</p>
                </div>
              ))
            ) : (
              <p className="text-center text-sm font-medium">
                {isLoading ? `${t("common.loading")}...` : t("common.no_results_found")}
              </p>
            )}
          </div>
          {totalCount > limit && (
            <button
              id="load-more-btn"
              className="flex h-9 w-full items-center justify-center rounded-lg bg-secondary text-sm text-white"
              onClick={loadMoreData}
            >
              <p>{t("common.more")}</p>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default SingleSelectWithSearchDropdown;
