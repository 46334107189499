// UI Components
import RoundButton from "@/components/buttons/RoundButton";
import SearchInput from "@/components/inputs/SearchInput";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { Badge, Table } from "flowbite-react";
import Pagination from "@/components/utils/Pagination";
import NotificationToast from "@/components/toasts/NotificationToast";
import ConfirmationModal from "@/components/modals/ConfirmationModal";

// React and Routing
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Icons
import { ReactComponent as FilterIcon } from "@/assets/icons/svg/filterIcon.svg";
import { ReactComponent as CircleArrowRightIcon } from "@/assets/icons/svg/arrows/circleArrow/circleArrowRightIcon.svg";

// Types
import { SortColumn } from "@/types/employee";
import { SortOrder } from "@/types/util";
import { CommissionSchemeListData } from "@/types/scheme";

// API and Utils
import { useDeleteCommissionScheme, useGetCommissionSchemeList } from "@/api/scheme/commissionScheme.store";
import { showToast } from "@/components/toasts/toast";
import { tableTheme } from "@/flowbiteThemes/tableTheme";
import { handleError } from "@/utils/functions";
import LoadingSpinner from "@/components/utils/LoadingSpinner";

const CommissionScheme = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const tableHeadCells = ["scheme_code", "scheme_name", "cycle", "action"];

  // fetch get commission scheme list
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(10);
  const [sortColumn, setSortColumn] = useState<SortColumn>("");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>("");

  const {
    data: commissionSchemeListData,
    isLoading: isLoadingCommissionSchemeList,
    isError: isErrorCommissionSchemeList,
    refetch: refetchCommissionSchemeList,
    error: errorCommissionSchemeList,
  } = useGetCommissionSchemeList(currentPage - 1, currentLimit, undefined, undefined, debouncedSearchValue);

  // handle error
  if (isErrorCommissionSchemeList) {
    showToast.error(errorCommissionSchemeList.message || t("common.error"));
  }

  // fetch delete commission scheme
  const { mutate: deleteCommissionScheme } = useDeleteCommissionScheme();

  const handleDeleteCommissionScheme = (id: number | null) => {
    if (!id) return;
    deleteCommissionScheme(id, {
      onSuccess: () => {
        showToast.success(t("common.success"));
        setIsDeleteModalOpen(false);
        refetchCommissionSchemeList();
      },
      onError: (error) => {
        const errorMessage = handleError(error);
        showToast.error(errorMessage);
      },
    });
  };

  // delete confirmation modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<number | null>(null);
  const [selectedDeleteTitle, setSelectedDeleteTitle] = useState("");

  const handleDeleteState = (id: number | null, title: string) => {
    setSelectedDeleteId(id);
    setSelectedDeleteTitle(title);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModal = () => (
    <ConfirmationModal
      isOpen={isDeleteModalOpen}
      onClose={() => setIsDeleteModalOpen(false)}
      title={t("common.delete_confirmation")}
    >
      <div className="text-dark w-full">
        <header className="w-full text-base font-semibold">
          {t("common.delete_confirmation_message", { item: selectedDeleteTitle })}
        </header>
        <button className="confirm-modal-btn" onClick={() => handleDeleteCommissionScheme(selectedDeleteId)}>
          {t("common.delete")}
        </button>
      </div>
    </ConfirmationModal>
  );

  return (
    <div id="CommissionScheme-main" className="h-full w-full">
      {handleDeleteModal()}
      <header id="CommissionScheme-header" className="flex w-full items-center gap-6">
        <SearchInput inputChange={(value) => setDebouncedSearchValue(value)} />
        <RoundButton
          text={t("common.filter")}
          onClick={() => {}}
          height="48px"
          py="3"
          px="6"
          gap="12px"
          iconRight={<SVGIcon icon={FilterIcon} fill="#ffffff" width="24" height="24" />}
          borderRadius="12px"
          fontWeight="600"
        />
        <RoundButton
          text={t("commission_scheme.create_commission_scheme")}
          onClick={() => navigate(`${location.pathname}/create-commission-scheme`)}
          height="48px"
          py="3"
          px="6"
          gap="12px"
          iconLeft={<SVGIcon icon={CircleArrowRightIcon} fill="#030623" width="24" height="24" />}
          borderRadius="12px"
          bgColor="bg-secondary"
          textColor="text-primary"
          fontWeight="600"
        />
      </header>
      <main id="main table" className="relative mt-6 overflow-x-auto">
        {isLoadingCommissionSchemeList && <LoadingSpinner />}
        <Table theme={tableTheme}>
          <Table.Head className="h-[72px]">
            {tableHeadCells.map((tableHeadCell, index) => {
              return (
                <React.Fragment key={index}>
                  <Table.HeadCell className="bg-primary text-base font-medium text-white">
                    <div className="flex items-center gap-2">
                      <p>{t(`commission_scheme.${tableHeadCell}`)}</p>
                    </div>
                  </Table.HeadCell>
                </React.Fragment>
              );
            })}
          </Table.Head>
          <Table.Body className="divide-y border-b border-b-[#71707180]">
            {commissionSchemeListData?.data?.map((res: CommissionSchemeListData) => (
              <React.Fragment key={res.id}>
                <Table.Row className="text-dark h-[83px] bg-white text-sm font-medium">
                  <Table.Cell id="scheme_code" className="whitespace-nowrap">
                    {res.code}
                  </Table.Cell>
                  <Table.Cell id="scheme_name">{res.name}</Table.Cell>
                  <Table.Cell id="cycle">{t(`date.${res.cycle.toLowerCase()}`)}</Table.Cell>
                  <Table.Cell id="action">
                    <div className="flex gap-3">
                      <Badge className="badge-purple">{t("common.view_details")}</Badge>
                      <Badge className="badge-red" onClick={() => handleDeleteState(res.id, res.name)}>
                        {t("common.delete")}
                      </Badge>
                    </div>
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        </Table>
      </main>
      <footer className="w-full">
        {commissionSchemeListData && (
          <Pagination
            currentPage={currentPage}
            totalPages={commissionSchemeListData.totalPages}
            totalCount={commissionSchemeListData.totalCount}
            currentLength={commissionSchemeListData.data.length}
            onPageChange={(page: number) => setCurrentPage(page)}
            onLimitChange={(limit: number) => setCurrentLimit(limit)}
          />
        )}
      </footer>
    </div>
  );
};
export default CommissionScheme;
