import { setApiError } from "@/redux/setting/settingSlice";
import store from "@/redux/store";
import axios from "axios";
import { QueryClient } from "@tanstack/react-query";
import { setOrganisationCode, getOrganisationCode, getToken, removeToken } from "@/utils/localStorage";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

const getOrganisationCodeFromPath = () => {
  const currentUrl = window.location.href;
  const urlObj = new URL(currentUrl);
  const pathname = urlObj.pathname;
  return pathname.split("/")[2];
};

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  const organisationCode = getOrganisationCodeFromPath();

  if (organisationCode) {
    const storedOrganisationCode = getOrganisationCode();
    if (storedOrganisationCode !== organisationCode) {
      setOrganisationCode(organisationCode);
    }

    config.baseURL = `${config.baseURL}/${organisationCode}`;
  }

  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      console.error("API response error:", error.response);

      store.dispatch(setApiError(error.response.data));

      const organisationCode = getOrganisationCode();
      const isLoginPage = window.location.pathname.split("/")[3] === "login";

      if (error.response.status === 404 && isLoginPage) {
        removeToken();
        return;
      }

      if (error.response.status === 401) {
        removeToken();

        if (!isLoginPage) {
          window.open(`/cms/${organisationCode}/login`, "_self");
        }
      }
    }
    return Promise.reject(error);
  }
);

export { api };
