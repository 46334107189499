import TitleButton from "@/components/buttons/TitleButton";
import { useTranslation } from "react-i18next";
import RoundButton from "@/components/buttons/RoundButton";
import { ReactComponent as BackIcon } from "@/assets/icons/svg/backIcon.svg";
import { ReactComponent as NextIcon } from "@/assets/icons/svg/nextIcon.svg";
import { ReactComponent as SaveIcon } from "@/assets/icons/svg/saveIcon.svg";
import { ReactComponent as CircleAddIcon } from "@/assets/icons/svg/circleAddIcon.svg";
import { ReactComponent as DatePickerIcon } from "@/assets/icons/svg/datePickerIcon.svg";
import { ReactComponent as TriangleArrowDownIcon } from "@/assets/icons/svg/arrows/triangleArrow/triangleArrowDownIcon.svg";
import { ReactComponent as TriangleArrowUpIcon } from "@/assets/icons/svg/arrows/triangleArrow/triangleArrowUpIcon.svg";
import { ReactComponent as SearchMagnifierIcon } from "@/assets/icons/svg/searchMagnifierIcon.svg";
import { ReactComponent as CrossIcon } from "@/assets/icons/svg/crossIcon.svg";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Checkbox, FloatingLabel, Label } from "flowbite-react";
import { floatingLabelTheme } from "@/flowbiteThemes/floatingLabelTheme";
import { useEffect, useState } from "react";
import CustomDatePicker from "@/components/pickers/CustomDatePicker";
import {
  formatDateByHyphen,
  formatDateBySlashYYYYMMDD,
  formatDateFromHyphenToSlash,
  formatYearMonthFromHyphenToSlash,
  getOrganisationCode,
  handleCaughtError,
  numberOnly,
} from "@/utils/functions";
import { EmploymentType, Gender, PayType, PositionChangeType, PositionWorkMode, SalaryChangeType } from "@/types/enums";
import { EmployeeListData, EmployeeListResponse, Salary } from "@/types/employee";
import { HiOutlineX } from "react-icons/hi";
import { createEmployee, getCommissionSchemeList, getEmployeeList } from "@/api";
import { CommissionSchemeListData } from "@/types/scheme";
import LoadingSpinner from "@/components/utils/LoadingSpinner";
import { checkboxTheme } from "@/flowbiteThemes/checkboxTheme";
import { CreatePayrollT } from "@/types/payroll";
import SingleSelectWithSearchDropdown from "@/components/dropdowns/SingleSelectWithSearchDropdown";
import FloatingDatePicker from "@/components/pickers/FloatingDatePicker";
import SingleSelectDropdown from "@/components/dropdowns/SingleSelectDropdown";

const feEmploymentType = [EmploymentType.FULL_TIME, EmploymentType.PART_TIME, EmploymentType.INTERN];

const CreatePayroll = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const searchParams = new URLSearchParams(location.search);
  const queryParamsByTab = searchParams.get("tab");

  const initialCreatePayrollBody: CreatePayrollT = {
    employeeId: null,
    yearMonth: null,
    employeeInfo: {
      code: null,
      name: null,
      hkid: null,
      department: {
        name: null,
      },
      position: {
        id: null,
        name: null,
        type: null,
      },
      bankAccount: null,
      contractId: null,
      workDayPerWeek: null,
      workHourPerDay: null,
    },
    payInfo: {
      basicSalary: null,
      commissionInfo: [
        {
          commissionScheme: {
            id: null,
            name: null,
            cycle: null,
            items: [
              {
                min: null,
                max: null,
                percentage: null,
              },
            ],
          },
          client: {
            id: null,
            name: null,
          },
          salesAmount: null,
        },
      ],
      transportationAllowance: null,
      specialAllowance: null,
      bonus: null,
      typhoonPay: null,
      other: null,
      mpfPercentage: {
        employee: null,
        employer: null,
      },
    },
    leaveInfo: {
      paid: {
        restDay: {
          record: [],
          amount: null,
        },
        statutoryHoliday: {
          record: [
            {
              id: null, // Holiday Record ID
              // ...Holiday Record Data
            },
          ],
          amount: null,
        },
        annualLeave: {
          record: [
            {
              id: null, // Leave Record ID
              // ...Leave Record Data
            },
          ],
          amount: null,
        },
        sickLeave: {
          record: [],
          amount: null,
        },
        injuryLeave: {
          record: [],
          amount: null,
        },
        maternityLeave: {
          record: [],
          amount: null,
        },
        birthdayLeave: {
          record: [],
          amount: null,
        },
      },
      unpaid: {
        restDay: {
          record: [],
          amount: null,
        },
        statutoryHoliday: {
          record: [],
          amount: null,
        },
        annualLeave: {
          record: [],
          amount: null,
        },
        sickLeave: {
          record: [],
          amount: null,
        },
        probationLeave: {
          record: [],
          amount: null,
        },
        resignLeave: {
          record: [],
          amount: null,
        },
      },
      partialPaid: {
        sickLeave: {
          record: [],
          amount: null,
        },
        injuryLeave: {
          record: [],
          amount: null,
        },
        maternityLeave: {
          record: [
            {
              id: null, // Leave Record ID
              // ...Leave Record Data
            },
          ],
          amount: null,
        },
      },
    },
    paymentInfo: {
      method: null,
      refNo: null,
      date: null,
    },
  };

  const [createPayrollData, setCreatePayrollData] = useState(initialCreatePayrollBody);
  const [isLoading, setIsLoading] = useState(false);

  // handle tabs and back / next button
  const tabData = ["employee_details", "pay_information", "leave_information"];
  const handleNext = () => {
    const tabMapping: Record<string, string> = {
      employee_details: "pay_information",
      pay_information: "leave_information",
    };

    if (queryParamsByTab && tabMapping[queryParamsByTab]) {
      const nextTab = tabMapping[queryParamsByTab];
      searchParams.set("tab", nextTab);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  };
  const handleBack = () => {
    const tabMapping: Record<string, string> = {
      pay_information: "employee_details",
      leave_information: "pay_information",
    };

    if (queryParamsByTab && tabMapping[queryParamsByTab]) {
      const nextTab = tabMapping[queryParamsByTab];
      searchParams.set("tab", nextTab);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  };

  // handle input change
  // Utility function for deep object update
  const deepUpdate = (obj: any, path: string[], value: any) => {
    if (path.length === 1) {
      obj[path[0]] = value;
    } else {
      if (!obj[path[0]]) obj[path[0]] = {};
      deepUpdate(obj[path[0]], path.slice(1), value);
    }
  };

  // General input change handler
  const handleInputChange = (path: string, value: any) => {
    setCreatePayrollData((prevState) => {
      const updatedState = { ...prevState };
      deepUpdate(updatedState, path.split("."), value);
      return updatedState;
    });
  };

  //INPUT FOCUS:
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [selectedInputType, setSelectedInputType] = useState<string>("");

  //employee_details
  const [isYearMonthDatePickerOpen, setIsYearMonthDatePickerOpen] = useState<boolean>(false);

  //pay_information
  const [isEmploymentTypeDropdownOpen, setIsEmploymentTypeDropdownOpen] = useState<boolean>(false);

  //leave_information

  const handleInputFocus = (isFocus: boolean, type: "yearMonth" | "employment_type") => {
    setIsInputFocused(isFocus);
    setSelectedInputType(type);

    if (type === "yearMonth") {
      setIsYearMonthDatePickerOpen(true);
    }

    if (type === "employment_type") {
      if (!isFocus) {
        setTimeout(() => {
          setIsEmploymentTypeDropdownOpen(false);
        }, 200);
      }
      setIsEmploymentTypeDropdownOpen(true);
    }
  };

  // Call the API with the searchValue (for employee list dropdown)
  const [employeeListResponse, setEmployeeListResponse] = useState<EmployeeListResponse>();
  const fetchEmployeeOptions = async (searchValue: string, limit: number) => {
    const response = await getEmployeeList(0, limit, "", "desc", searchValue);
    setEmployeeListResponse(response.data);
    return response.data.data.map((res: any) => ({
      id: res.id,
      name: res.name,
    }));
  };

  const handleSelectedEmployee = (option: { id: number | null; name: string | null }) => {
    // missing position id, waiting for jackal add to get employee list data
    const filteredData = employeeListResponse?.data?.filter((item) => item.id === option.id);
    console.log("filteredData:", filteredData);
    if (filteredData) {
      handleInputChange("employeeInfo.code", filteredData[0].code);
      handleInputChange("employeeInfo.name", filteredData[0].name);
      handleInputChange("employeeInfo.hkid", filteredData[0].hkid);
      handleInputChange("employeeInfo.position.name", filteredData[0].position);
      handleInputChange("employeeInfo.position.type", filteredData[0].employmentType);
      handleInputChange("employeeInfo.bankAccount", filteredData[0].bankAccount);
    }
  };

  console.log("create payroll :", createPayrollData);

  return (
    <div className="w-full">
      <TitleButton
        title={t("payroll.create_payroll")}
        onClick={() => navigate(`/cms/${params.organisationCode}/payroll`)}
      />

      <header className="mt-6 flex w-full items-center justify-between">
        <section id="CreatePayroll-tabs" className="flex w-full items-center gap-6">
          {tabData.map((tab, index) => {
            return (
              <figure
                key={index}
                className={`w-fit py-3 text-sm font-semibold 
                  ${
                    queryParamsByTab === tab
                      ? "cursor-pointer border-b-2 border-secondary text-primary"
                      : "border-lightgrey cursor-default text-[#71707180] hover:border-b-2"
                  } `}
              >
                {t(`payroll.${tab}`)}
              </figure>
            );
          })}
        </section>
        <section id="CreatePayroll-action-buttons" className="flex w-fit items-center gap-3">
          {queryParamsByTab === "leave_information" && (
            <RoundButton
              text={t("common.save")}
              onClick={() => {}}
              height="48px"
              bgColor="bg-secondary"
              textColor="text-dark"
              fontWeight="600"
              px="6"
              py="3"
              gap="12px"
              borderRadius="12px"
              iconLeft={<SVGIcon icon={SaveIcon} fill="#030623" width="24" height="24" />}
              disabled={isLoading}
            />
          )}
          <RoundButton
            text={t("common.back")}
            onClick={handleBack}
            height="48px"
            bgColor="bg-secondary"
            textColor="text-dark"
            fontWeight="600"
            px="6"
            py="3"
            gap="12px"
            borderRadius="12px"
            iconLeft={<SVGIcon icon={BackIcon} fill="#030623" width="24" height="24" />}
            disabled={queryParamsByTab === "employee_details" || isLoading}
          />
          <RoundButton
            text={t("common.next")}
            onClick={handleNext}
            height="48px"
            bgColor="bg-secondary"
            textColor="text-dark"
            fontWeight="600"
            px="6"
            py="3"
            gap="12px"
            borderRadius="12px"
            iconLeft={<SVGIcon icon={NextIcon} fill="#030623" width="24" height="24" />}
            disabled={queryParamsByTab === "commission_scheme"}
          />
        </section>
      </header>
      {queryParamsByTab === "employee_details" && (
        <main id="CreatePayroll-main employee_details" className="mt-9 flex w-full flex-col gap-6">
          <section className="flex w-full items-center gap-3">
            <div className="w-[33%]">
              <FloatingDatePicker
                label={t("payroll.time")}
                value={createPayrollData.yearMonth}
                onChange={(value) => handleInputChange("yearMonth", formatDateByHyphen(value)?.slice(0, 7))}
                byYearMonth
              />
            </div>
          </section>
          <section className="flex w-full items-center gap-3">
            {/* <SingleSelectWithSearchDropdown
              placeholder={t("employee.employee_name")}
              searchPlaceholder={t("employee.search_by_employee_name")}
              onSelect={(option) => {
                handleSelectedEmployee(option);
              }}
              fetchOptions={fetchEmployeeOptions}
              totalCount={employeeListResponse?.totalCount || 0}
            /> */}
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.employee_or_contract_id")}
                theme={floatingLabelTheme}
                className="min-w-[272px]"
                onChange={(e) => handleInputChange("employeeInfo.code", e.target.value)}
                value={createPayrollData.employeeInfo.code || ""}
              />
            </div>
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.hkid")}
                theme={floatingLabelTheme}
                className="min-w-[272px]"
                onChange={(e) => handleInputChange("employeeInfo.hkid", e.target.value)}
                value={createPayrollData.employeeInfo.hkid || ""}
              />
            </div>
          </section>
          <section className="flex w-full items-center gap-3">
            <SingleSelectDropdown
              translationKey="employee"
              label="employment_type"
              data={feEmploymentType}
              onSelect={(value) => handleInputChange("employeeInfo.position.type", value)}
              selectedValue={createPayrollData?.employeeInfo?.position?.type || ""}
              withTranslate
            />
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.department")}
                theme={floatingLabelTheme}
                onChange={(e) => handleInputChange("employeeInfo.department.name", e.target.value)}
                value={createPayrollData.employeeInfo?.department?.name || ""}
              />
            </div>
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.position")}
                theme={floatingLabelTheme}
                onChange={(e) => handleInputChange("employeeInfo.position.name", e.target.value)}
                value={createPayrollData.employeeInfo?.position?.name || ""}
              />
            </div>
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.bank_account_number")}
                theme={floatingLabelTheme}
                onChange={(e) => handleInputChange("employeeInfo.bankAccount", e.target.value)}
                value={createPayrollData.employeeInfo?.bankAccount || ""}
              />
            </div>
          </section>

          <section className="flex w-full items-center gap-3">
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("payroll.no_of_working_days_per_week")}
                theme={floatingLabelTheme}
                value={createPayrollData.employeeInfo?.workDayPerWeek || ""}
                onChange={(e) => handleInputChange("employeeInfo.workDayPerWeek", numberOnly(e.target.value))}
              />
            </div>
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("payroll.daily_working_hours")}
                theme={floatingLabelTheme}
                value={createPayrollData.employeeInfo?.workHourPerDay || ""}
                onChange={(e) => handleInputChange("employeeInfo.workHourPerDay", numberOnly(e.target.value))}
              />
            </div>
          </section>
        </main>
      )}
      {queryParamsByTab === "pay_information" && <main id="CreatePayroll-main pay_information"></main>}
      {queryParamsByTab === "leave_information" && <main id="CreatePayroll-main leave_information"></main>}
    </div>
  );
};

export default CreatePayroll;
