import { ReactComponent as SearchMagnifierIcon } from "@/assets/icons/svg/searchMagnifierIcon.svg";
import SVGIcon from "../SVGIcons/SVGIcon";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { convertHyphensToUnderscores } from "@/utils/functions";
import { useEffect, useState } from "react";

type Props = {
  inputChange: (value: string) => void;
};

const SearchInput = ({ inputChange }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState("");
  const currentPage = convertHyphensToUnderscores(location.pathname.split("/")[3]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      inputChange(searchValue);
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchValue, inputChange]);

  const handleInputChange = (value: string) => {
    setSearchValue(value);
  };

  const renderMessageByCurrentPage = (): string => {
    const messageMap: Record<string, string> = {
      employee_management: "employee_management_search_message",
      client: "client_search_message",
      commission_scheme: "commission_scheme_search_message",
      leave: "leave_search_message",
      holiday: "holiday_search_message",
      payroll: "payroll_search_message",
    };

    return messageMap[currentPage] || "default_search_message";
  };

  return (
    <div id="search-input" className="flex w-full flex-1 items-center gap-3 rounded-xl border border-[#030623] p-1.5">
      <figure id="icon" className="ml-[12px] flex h-6 w-6 items-center justify-center">
        <SVGIcon icon={SearchMagnifierIcon} fill="#717071" width="24" height="24" />
      </figure>
      <section id="input" className="w-full">
        <input
          type="text"
          placeholder={t(`search.${renderMessageByCurrentPage()}`)}
          className="w-full border-none px-0 text-sm font-medium focus:ring-0"
          onChange={(e) => handleInputChange(e.target.value)}
          value={searchValue}
        />
      </section>
    </div>
  );
};

export default SearchInput;
