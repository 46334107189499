import RoundButton from "@/components/buttons/RoundButton";
import SearchInput from "@/components/inputs/SearchInput";
import { useState } from "react";
import { ReactComponent as FilterIcon } from "@/assets/icons/svg/filterIcon.svg";
import { ReactComponent as TableSortIcon } from "@/assets/icons/svg/tableSortIcon.svg";
import { ReactComponent as CircleArrowRightIcon } from "@/assets/icons/svg/arrows/circleArrow/circleArrowRightIcon.svg";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { Badge, Table } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { tableTheme } from "@/flowbiteThemes/tableTheme";

const Payroll = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);

  const tableHeadCells = ["employee_code", "employee_name", "year_month", "version", "total_pay", "action"];

  return (
    <div id="Payroll-main" className="h-full w-full">
      <header id="Payroll-header" className="flex w-full items-center gap-6">
        <SearchInput inputChange={() => {}} />
        <RoundButton
          text={t("common.filter")}
          onClick={() => {}}
          width="118px"
          height="48px"
          py="3"
          px="6"
          gap="12px"
          iconRight={<SVGIcon icon={FilterIcon} fill="#ffffff" width="24" height="24" />}
          borderRadius="12px"
          fontWeight="600"
        />
        <RoundButton
          text={t("payroll.create_payroll")}
          onClick={() => navigate(`${location.pathname}/create-payroll?tab=employee_details`)}
          height="48px"
          py="3"
          px="6"
          gap="12px"
          iconLeft={<SVGIcon icon={CircleArrowRightIcon} fill="#030623" width="24" height="24" />}
          borderRadius="12px"
          bgColor="bg-secondary"
          textColor="text-primary"
          fontWeight="600"
        />
      </header>
      <main id="main table" className="mt-6 overflow-x-auto">
        <Table theme={tableTheme}>
          <Table.Head className="h-[72px]">
            {tableHeadCells.map((tableHeadCell, index) => {
              return (
                <Table.HeadCell key={index} className="bg-primary text-base font-medium text-white">
                  <div className="flex items-center gap-2">
                    <p>{t(`payroll.${tableHeadCell}`)}</p>
                    {tableHeadCell !== "action" && (
                      <figure className="hover-scale-sm cursor-pointer">
                        <SVGIcon icon={TableSortIcon} fill="#ffffff" width="24" height="24" />
                      </figure>
                    )}
                  </div>
                </Table.HeadCell>
              );
            })}
          </Table.Head>
          <Table.Body className="divide-y">
            <Table.Row className="text-dark h-[83px] border-b border-b-[#71707180] bg-white text-sm font-medium">
              <Table.Cell id="employee_code" className="whitespace-nowrap">
                EMP010
              </Table.Cell>
              <Table.Cell id="employee_name" className="whitespace-nowrap">
                Yu Lok Ching Samantha
              </Table.Cell>
              <Table.Cell id="year_month">2024/09</Table.Cell>
              <Table.Cell id="version">V1.0</Table.Cell>
              <Table.Cell id="total_pay">$36,000</Table.Cell>
              <Table.Cell id="action">
                <div className="flex gap-3">
                  <Badge className="badge-purple">{t("common.view_details")}</Badge>
                  <Badge className="badge-red">{t("common.delete")}</Badge>
                </div>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </main>
      <footer className="w-full">
        {/* <Pagination currentPage={currentPage} totalPages={10} onPageChange={(page: number) => setCurrentPage(page)} /> */}
      </footer>
    </div>
  );
};
export default Payroll;
