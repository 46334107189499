import { useMutation, useQuery, UseQueryResult } from "@tanstack/react-query";
import { SortColumn } from "@/types/employee";
import { SortOrder } from "@/types/util";
import { createHoliday, deleteHoliday, getHolidayList, updateHoliday } from "./holiday.api";
import { CreateHoliday, HolidayListResponse, UpdateHoliday } from "@/types/holiday";
import { HolidayType } from "@/types/enums";

// create holiday
export const useCreateHoliday = () => {
  return useMutation({
    mutationFn: (data: CreateHoliday) => createHoliday(data),
  });
};

// update holiday
export const useUpdateHoliday = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: UpdateHoliday }) => updateHoliday(id, data),
  });
};

// get holiday list
export const useGetHolidayList = (
  page: number,
  limit: number,
  sortColumn?: SortColumn,
  sortOrder?: SortOrder,
  searchValue?: string,
  type?: HolidayType,
  yearMonth?: string,
  startDate?: string,
  endDate?: string
): UseQueryResult<HolidayListResponse> => {
  return useQuery({
    queryKey: ["holidayList", page, limit, sortColumn, sortOrder, searchValue, type, yearMonth, startDate, endDate],
    queryFn: () =>
      getHolidayList(page, limit, sortColumn, sortOrder, searchValue, type, yearMonth, startDate, endDate).then(
        (res) => res.data
      ),
  });
};

// delete holiday
export const useDeleteHoliday = () => {
  return useMutation({
    mutationFn: (id: number) => deleteHoliday(id),
  });
};

