export enum Gender {
  MALE = "M",
  FEMALE = "F",
  OTHER = "N/A",
}

export enum PositionWorkMode {
  MON_FRI = "MON_FRI",
  CUSTOM = "CUSTOM",
}

export enum EmploymentType {
  FULL_TIME = "FULL_TIME",
  PART_TIME = "PART_TIME",
  INTERN = "INTERN",
}

export enum PositionChangeType {
  PROMOTION = "PROMOTION",
  TRANSFER = "TRANSFER",
}

export enum PayType {
  ANNUALLY = "ANNUALLY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
  DAILY = "DAILY",
  HOURLY = "HOURLY",
}

export enum SalaryChangeType {
  NORMAL = "NORMAL",
  PROMOTION = "PROMOTION",
}
