import CmsRouteLayout from "@/components/layouts/CmsRouteLayout";
import Client from "@/pages/client/Client";
import CreateClient from "@/pages/client/CreateClient";
import Dashboard from "@/pages/Dashboard";
import CreateEmployee from "@/pages/employee/CreateEmployee";
import EmployeeManagement from "@/pages/employee/EmployeeManagement";
import CreateHolidayRecord from "@/pages/holiday/CreateHolidayRecord";
import HolidayRecord from "@/pages/holiday/HolidayRecord";
import CreateLeaveRecord from "@/pages/leave/CreateLeaveRecord";
import LeaveRecord from "@/pages/leave/LeaveRecord";
import Login from "@/pages/Login";
import PageNotFound from "@/pages/PageNotFound";
import CreatePayroll from "@/pages/payroll/CreatePayroll";
import Payroll from "@/pages/payroll/Payroll";
import CommissionScheme from "@/pages/scheme/CommissionScheme";
import CreateCommissionScheme from "@/pages/scheme/CreateCommissionScheme";
import { Route, Routes } from "react-router-dom";

const CmsRoutes = () => {
  return (
    <>
      <Routes>
        <Route path=":organisationCode/*" element={<CmsRouteLayout />}>
          <Route path="login" index element={<Login />} />
          <Route path="dashboard" index element={<Dashboard />} />
          <Route path="employee-management/*">
            <Route index element={<EmployeeManagement />} />
            <Route path="create-employee" element={<CreateEmployee />} />
          </Route>
          <Route path="client/*">
            <Route index element={<Client />} />
            <Route path="create-client" element={<CreateClient />} />
          </Route>
          <Route path="commission-scheme/*">
            <Route index element={<CommissionScheme />} />
            <Route path="create-commission-scheme" element={<CreateCommissionScheme />} />
          </Route>
          <Route path="leave/*">
            <Route index element={<LeaveRecord />} />
            <Route path="create-leave-record" element={<CreateLeaveRecord />} />
          </Route>
          <Route path="holiday/*">
            <Route index element={<HolidayRecord />} />
            <Route path="create-holiday-record" element={<CreateHolidayRecord />} />
          </Route>
          <Route path="payroll/*">
            <Route index element={<Payroll />} />
            <Route path="create-payroll" element={<CreatePayroll />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

export default CmsRoutes;
