import { floatingLabelTheme, floatingLabelThemeForAppraisal } from "@/flowbiteThemes/floatingLabelTheme";
import { FloatingLabel } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "../SVGIcons/SVGIcon";
import { ReactComponent as TriangleArrowDownIcon } from "@/assets/icons/svg/arrows/triangleArrow/triangleArrowDownIcon.svg";
import { ReactComponent as TriangleArrowUpIcon } from "@/assets/icons/svg/arrows/triangleArrow/triangleArrowUpIcon.svg";

type Props = {
  translationKey: string;
  label: string;
  data: string[] | number[];
  onSelect: (value: any) => void;
  selectedValue: string | number;
  isAppraisal?: boolean;
  withTranslate?: boolean;
  ids?: number[];
  isReadOnly?: boolean;
};

const SingleSelectDropdown = ({
  translationKey,
  label,
  data,
  onSelect,
  selectedValue,
  isAppraisal,
  withTranslate,
  ids,
  isReadOnly,
}: Props) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const handleOnClick = (value: string | number) => {
    onSelect(value);
  };

  const handleOnClickWithId = (index: number) => {
    if (ids) {
      onSelect(ids[index]);
    }
  };

  const onClose = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 200);
  };

  const handleSelectedValue = () => {
    if (!selectedValue) return "";
    if (typeof selectedValue === "string" && withTranslate) {
      return t(`${translationKey}.${selectedValue}`);
    }
    return selectedValue;
  };

  const handleItemName = (item: string | number) => {
    if (typeof item === "string" && withTranslate) {
      return t(`${translationKey}.${item}`);
    }
    return item;
  };

  // handle click-outside
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={`relative w-full ${isReadOnly ? "cursor-default opacity-90" : ""}`}>
      <section className="flex w-full items-center">
        <div className="w-full">
          <FloatingLabel
            variant="filled"
            label={isAppraisal ? "" : t(`${translationKey}.${label}`)}
            theme={isAppraisal ? floatingLabelThemeForAppraisal : floatingLabelTheme}
            className={isReadOnly ? "cursor-default opacity-50" : "cursor-pointer"}
            readOnly
            onFocus={() => setIsOpen(true)}
            onBlur={onClose}
            value={handleSelectedValue()}
          />
        </div>
        {!isReadOnly && (
          <figure className="absolute right-[10px] cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
            <SVGIcon
              icon={isOpen ? TriangleArrowUpIcon : TriangleArrowDownIcon}
              fill="#717071"
              width="24"
              height="24"
            />
          </figure>
        )}
      </section>

      {isOpen && !isReadOnly && (
        <div
          ref={dropdownRef}
          className={`absolute right-0 top-[100%] z-20 max-h-[200px] w-full min-w-fit overflow-y-auto rounded-lg bg-white p-3 shadow`}
        >
          {data?.map((item, index) => {
            return (
              <div
                key={index}
                className="text-dark w-full cursor-pointer rounded-lg px-3 py-1.5 text-sm font-medium hover:bg-[#0306230D]"
                onClick={() => {
                  handleOnClick(item);
                  handleOnClickWithId(index);
                  onClose();
                }}
              >
                {handleItemName(item)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SingleSelectDropdown;
