import React, { useState, useEffect, useRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { enGB } from "date-fns/locale"; // Locale where week starts on Monday
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatePicker.css";
import { Dropdown } from "flowbite-react";
import SVGIcon from "../SVGIcons/SVGIcon";
import { ReactComponent as TriangleArrowDownIcon } from "@/assets/icons/svg/arrows/triangleArrow/triangleArrowDownIcon.svg";
import { HiOutlineX } from "react-icons/hi";

type Props = {
  onSelectedDate: (value: Date | null) => void;
  onClose: () => void;
  isDob?: boolean;
  byYearMonth?: boolean;
};

registerLocale("en-GB", enGB);

const CustomDatePicker = ({ onSelectedDate, onClose, isDob, byYearMonth }: Props) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null); // For automatic scroll

  const handleSelectedDate = (date: Date | null) => {
    setStartDate(date);
    onSelectedDate(date);
  };

  const currentYear = new Date().getFullYear();
  const yearRangeStart = currentYear + 5;

  // Use useEffect to scroll to the bottom when the dropdown is opened
  useEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.scrollTop = dropdownRef.current.scrollHeight;
    }
  }, []);

  const customDropdownTrigger = (value: string) => {
    return (
      <div className="text-dark hover-scale-sm flex w-fit cursor-pointer items-center gap-[6px] rounded-md bg-white px-3 py-2.5 text-lg font-medium shadow">
        <span>{value}</span>
        <SVGIcon icon={TriangleArrowDownIcon} fill="#717071" width="24" height="24" />
      </div>
    );
  };

  // Custom Header for DatePicker
  const renderCustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: any) => {
    const selectedMonth = new Date(date).getMonth();
    const selectedYear = new Date(date).getFullYear();

    return (
      <div className="flex justify-between px-4 py-2">
        <button
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          className="hover-scale-sm flex h-11 w-11 items-center justify-center rounded-full bg-white text-2xl text-[#717071] shadow"
        >
          {"<"}
        </button>
        <div className="flex items-center space-x-2">
          <Dropdown
            label=""
            renderTrigger={() =>
              customDropdownTrigger(new Date(0, selectedMonth).toLocaleString("en-US", { month: "short" }))
            }
            className="max-h-[300px] overflow-scroll"
          >
            {Array.from({ length: 12 }, (_, i) => (
              <Dropdown.Item
                key={i}
                onClick={() => {
                  changeMonth(i);
                }}
              >
                {new Date(0, i).toLocaleString("en-US", { month: "short" })}
              </Dropdown.Item>
            ))}
          </Dropdown>
          <Dropdown
            label=""
            renderTrigger={() => customDropdownTrigger(selectedYear.toString())}
            className="max-h-[300px] overflow-scroll"
          >
            <div ref={dropdownRef}>
              {Array.from({ length: yearRangeStart - 1950 + 1 }, (_, i) => (
                <Dropdown.Item
                  key={i}
                  onClick={() => {
                    changeYear(yearRangeStart - i);
                  }}
                >
                  {yearRangeStart - i}
                </Dropdown.Item>
              ))}
            </div>
          </Dropdown>
        </div>
        <button
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          className="hover-scale-sm flex h-11 w-11 items-center justify-center rounded-full bg-white text-2xl text-[#717071] shadow"
        >
          {">"}
        </button>
      </div>
    );
  };

  // Function to render custom month content with tooltip
  const renderMonthContent = (month: number, shortMonth: string, longMonth: string, day: Date) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };

  return (
    <div className="relative">
      {/* <figure
        className="hover-scale-sm absolute right-[-2%] top-[-2%] z-30 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-white text-2xl text-[#717071] shadow"
        onClick={onClose}
      >
        <HiOutlineX className=" h-4 w-4 text-[#717071B3]" />
      </figure> */}
      {byYearMonth ? (
        // Custom Month and Year Picker with Custom Styles
        <DatePicker
          locale="en-GB"
          selected={startDate}
          onChange={(date) => handleSelectedDate(date)}
          renderMonthContent={renderMonthContent}
          showMonthYearPicker
          dateFormat="MM/yyyy"
          calendarClassName="custom-year-month-calendar"
          popperClassName="custom-popper"
          inline
        />
      ) : (
        // Regular Date Picker
        <DatePicker
          locale="en-GB"
          selected={startDate}
          onChange={(date) => handleSelectedDate(date)}
          renderCustomHeader={renderCustomHeader}
          calendarClassName="custom-calendar"
          dayClassName={(date) => "custom-day"}
          popperClassName="custom-popper"
          showPopperArrow={false}
          inline
        />
      )}
    </div>
  );
};

export default CustomDatePicker;
