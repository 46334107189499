// UI Components
import RoundButton from "@/components/buttons/RoundButton";
import SearchInput from "@/components/inputs/SearchInput";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { Badge, Table } from "flowbite-react";
import Pagination from "@/components/utils/Pagination";
import LoadingSpinner from "@/components/utils/LoadingSpinner";
import ConfirmationModal from "@/components/modals/ConfirmationModal";

// Icons
import { ReactComponent as FilterIcon } from "@/assets/icons/svg/filterIcon.svg";
import { ReactComponent as CircleArrowRightIcon } from "@/assets/icons/svg/arrows/circleArrow/circleArrowRightIcon.svg";

// React and Router
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Types
import { HolidayType } from "@/types/enums";
import { SortColumn } from "@/types/employee";
import { SortOrder } from "@/types/util";

// API and Utils
import { useDeleteHoliday, useGetHolidayList } from "@/api/holiday/holiday.store";
import { showToast } from "@/components/toasts/toast";
import { formatDateFromHyphenToSlash, handleError } from "@/utils/functions";

// Theme
import { tableTheme } from "@/flowbiteThemes/tableTheme";

const HolidayRecord = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const tableHeadCells = ["holiday_name", "holiday_type", "start_date", "end_date", "action"];

  // fetch holiday list
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(10);
  const [sortColumn, setSortColumn] = useState<SortColumn>("");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>("");
  const {
    data: holidayRecordListData,
    isLoading: isHolidayRecordListLoading,
    isError: isHolidayRecordListError,
    error: errorHolidayRecordList,
    refetch: refetchHolidayRecordList,
  } = useGetHolidayList(currentPage - 1, currentLimit, undefined, undefined, debouncedSearchValue);

  // handle error
  if (isHolidayRecordListError) {
    showToast.error(errorHolidayRecordList.message || t("common.error"));
  }

  // handle delete holiday
  const { mutate: deleteHoliday } = useDeleteHoliday();

  const handleDeleteHoliday = (id: number | null) => {
    if (!id) return;
    deleteHoliday(id, {
      onSuccess: () => {
        showToast.success(t("common.success"));
        setIsDeleteModalOpen(false);
        refetchHolidayRecordList();
      },
      onError: (error) => {
        const errorMessage = handleError(error);
        showToast.error(errorMessage);
      },
    });
  };

  // delete confirmation modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<number | null>(null);
  const [selectedDeleteTitle, setSelectedDeleteTitle] = useState("");

  const handleDeleteState = (id: number | null, title: string) => {
    setSelectedDeleteId(id);
    setSelectedDeleteTitle(title);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModal = () => (
    <ConfirmationModal
      isOpen={isDeleteModalOpen}
      onClose={() => setIsDeleteModalOpen(false)}
      title={t("common.delete_confirmation")}
    >
      <div className="text-dark w-full">
        <header className="w-full text-base font-semibold">
          {t("common.delete_confirmation_message", { item: selectedDeleteTitle })}
        </header>
        <button className="confirm-modal-btn" onClick={() => handleDeleteHoliday(selectedDeleteId)}>
          {t("common.delete")}
        </button>
      </div>
    </ConfirmationModal>
  );

  //handle holiday type badge styles
  const handleLeaveTypeBadgeStyles = (type: HolidayType) => {
    const typeStylesMapping = {
      STATUTORY: { backgroundColor: "#E509140D", color: "#E50914" },
      COMPANY: { backgroundColor: "#AA4FF60D", color: "#AA4FF6" },
      OTHER: { backgroundColor: "#2778930D", color: "#277893" },
    };
    return typeStylesMapping[type];
  };

  return (
    <div id="HolidayRecord-main" className="h-full w-full">
      {handleDeleteModal()}
      <header id="HolidayRecord-header" className="flex w-full items-center gap-6">
        <SearchInput inputChange={setDebouncedSearchValue} />
        <RoundButton
          text={t("common.filter")}
          onClick={() => {}}
          height="48px"
          py="3"
          px="6"
          gap="12px"
          iconRight={<SVGIcon icon={FilterIcon} fill="#ffffff" width="24" height="24" />}
          borderRadius="12px"
          fontWeight="600"
        />
        <RoundButton
          text={t("holiday.create_holiday_record")}
          onClick={() => navigate(`${location.pathname}/create-holiday-record`)}
          height="48px"
          py="3"
          px="6"
          gap="12px"
          iconLeft={<SVGIcon icon={CircleArrowRightIcon} fill="#030623" width="24" height="24" />}
          borderRadius="12px"
          bgColor="bg-secondary"
          textColor="text-primary"
          fontWeight="600"
        />
      </header>
      <main id="main table" className="relative mt-6 overflow-x-auto">
        {isHolidayRecordListLoading && <LoadingSpinner />}
        <Table theme={tableTheme}>
          <Table.Head className="h-[72px]">
            {tableHeadCells.map((tableHeadCell, index) => {
              return (
                <Table.HeadCell key={index} className="bg-primary text-base font-medium text-white">
                  <div className="flex items-center gap-2">
                    <p>{t(`holiday.${tableHeadCell}`)}</p>
                  </div>
                </Table.HeadCell>
              );
            })}
          </Table.Head>
          <Table.Body className="divide-y border-b border-b-[#71707180]">
            {holidayRecordListData?.data?.map((holidayRecord) => (
              <React.Fragment key={holidayRecord.id}>
                <Table.Row className="text-dark h-[83px] bg-white text-sm font-medium">
                  <Table.Cell id="holiday_name" className="whitespace-nowrap">
                    {holidayRecord.name}
                  </Table.Cell>
                  <Table.Cell id="holiday_type">
                    <Badge
                      className="flex h-9 w-fit items-center justify-center rounded-[3px] px-1.5 py-3 text-sm font-semibold"
                      style={handleLeaveTypeBadgeStyles(HolidayType.COMPANY)}
                    >
                      {t("holiday.company")}
                    </Badge>
                  </Table.Cell>
                  <Table.Cell id="start_date">{formatDateFromHyphenToSlash(holidayRecord.startDate)}</Table.Cell>
                  <Table.Cell id="end_date">{formatDateFromHyphenToSlash(holidayRecord.endDate)}</Table.Cell>
                  <Table.Cell id="action">
                    <div className="flex gap-3">
                      <Badge className="badge-purple">{t("common.view_details")}</Badge>
                      <Badge
                        className="badge-red"
                        onClick={() => handleDeleteState(holidayRecord.id, holidayRecord.name)}
                      >
                        {t("common.delete")}
                      </Badge>
                    </div>
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        </Table>
      </main>
      <footer className="w-full">
        {holidayRecordListData && (
          <Pagination
            currentPage={currentPage}
            totalPages={holidayRecordListData.totalPages}
            totalCount={holidayRecordListData.totalCount}
            currentLength={holidayRecordListData.data.length}
            onPageChange={(page: number) => setCurrentPage(page)}
            onLimitChange={(limit: number) => setCurrentLimit(limit)}
          />
        )}
      </footer>
    </div>
  );
};
export default HolidayRecord;
