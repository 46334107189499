import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ThemeProvider from "./components/theme/ThemeProvider";
import { Provider } from "react-redux";
import store from "./redux/store";
import "../src/locale/i18n";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./api/apiClient";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </QueryClientProvider>
);

reportWebVitals();
