// UI Components
import RoundButton from "@/components/buttons/RoundButton";
import SearchInput from "@/components/inputs/SearchInput";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { Avatar, Badge, Table } from "flowbite-react";
import Pagination from "@/components/utils/Pagination";
import LoadingSpinner from "@/components/utils/LoadingSpinner";
import ConfirmationModal from "@/components/modals/ConfirmationModal";

// Icons & Assets
import { ReactComponent as FilterIcon } from "@/assets/icons/svg/filterIcon.svg";
import { ReactComponent as CreateEmployeeIcon } from "@/assets/icons/svg/createEmployeeIcon.svg";
import userDefaultAvatar from "@/assets/defaults/userDefaultAvatar.png";

// Hooks
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDeleteEmployee, useGetEmployeeList } from "@/api/employee/employee.store";

// Types
import { SortColumn } from "@/types/employee";
import { SortOrder } from "@/types/util";

// Utils
import { formatDateFromHyphenToSlash, handleError } from "@/utils/functions";
import { showToast } from "@/components/toasts/toast";

const EmployeeManagement = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const tableHeadCells = [
    "employee",
    "email_address",
    "position",
    "employment_type",
    "basic_salary",
    "join_date",
    "action",
  ];

  // fetch get employee list
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(10);
  const [sortColumn, setSortColumn] = useState<SortColumn>("");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>("");

  const {
    data: employeeListData,
    isLoading: isLoadingEmployeeList,
    isError: isErrorEmployeeList,
    refetch: refetchEmployeeList,
    error: errorEmployeeList,
  } = useGetEmployeeList(currentPage - 1, currentLimit, undefined, undefined, debouncedSearchValue);

  // handle error
  if (isErrorEmployeeList) {
    showToast.error(errorEmployeeList.message || t("common.error"));
  }

  // handle gender
  const convertGender = (value: string) => {
    const map: Record<string, string> = {
      M: "male",
      F: "female",
      "N/A": "other",
    };
    return map[value];
  };
  const renderGenderStyles = (gender: string) => {
    const map: Record<string, object> = {
      M: { backgroundColor: "#2227F70D", color: "#2227F7" },
      F: { backgroundColor: "#FF006E26", color: "#FF006E" },
      "N/A": { backgroundColor: "#213A570D", color: "#213A57" },
    };
    return map[gender];
  };

  // fetch delete employee
  const { mutate: deleteEmployee } = useDeleteEmployee();

  const handleDeleteEmployee = (id: number | null) => {
    if (!id) return;
    deleteEmployee(id, {
      onSuccess: () => {
        showToast.success(t("common.success"));
        refetchEmployeeList();
        setIsDeleteModalOpen(false);
      },
      onError: (error: any) => {
        const errorMessage = handleError(error);
        showToast.error(errorMessage);
      },
    });
  };

  // delete item confirmation modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<number | null>(null);
  const [selectedDeleteTitle, setSelectedDeleteTitle] = useState("");

  const handleDeleteState = (id: number | null, title: string) => {
    setSelectedDeleteId(id);
    setSelectedDeleteTitle(title);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModal = () => (
    <ConfirmationModal
      isOpen={isDeleteModalOpen}
      onClose={() => setIsDeleteModalOpen(false)}
      title={t("common.delete_confirmation")}
    >
      <div className="text-dark w-full">
        <header className="w-full text-base font-semibold">
          {t("common.delete_confirmation_message", { item: selectedDeleteTitle })}
        </header>
        <button className="confirm-modal-btn" onClick={() => handleDeleteEmployee(selectedDeleteId)}>
          {t("common.delete")}
        </button>
      </div>
    </ConfirmationModal>
  );

  return (
    <div id="employee-management-main" className="h-full w-full">
      {handleDeleteModal()}
      <header id="employee-management-header" className="flex w-full items-center gap-6">
        <SearchInput inputChange={(value) => setDebouncedSearchValue(value)} />
        <RoundButton
          text={t("common.filter")}
          onClick={() => {}}
          height="48px"
          py="3"
          px="6"
          gap="12px"
          iconRight={<SVGIcon icon={FilterIcon} fill="#ffffff" width="24" height="24" />}
          borderRadius="12px"
          fontWeight="600"
        />
        <RoundButton
          text={t("employee.create_employee")}
          onClick={() => navigate(`${location.pathname}/create-employee?tab=personal_details`)}
          height="48px"
          py="3"
          px="6"
          gap="12px"
          iconLeft={<SVGIcon icon={CreateEmployeeIcon} fill="#ffffff" width="24" height="24" />}
          borderRadius="12px"
          bgColor="bg-secondary"
          textColor="text-primary"
          fontWeight="600"
        />
      </header>
      <main id="main table" className="relative mt-6 overflow-x-auto">
        {isLoadingEmployeeList && <LoadingSpinner />}
        <Table theme={theme}>
          <Table.Head className="h-[72px]">
            {tableHeadCells.map((tableHeadCell, index) => {
              return (
                <Table.HeadCell key={index} className="bg-primary text-base font-medium text-white">
                  <div className="flex items-center gap-2">
                    <p>{t(`employee.${tableHeadCell}`)}</p>
                    {/* {tableHeadCell !== "action" && (
                      <figure className="hover-scale-sm flex cursor-pointer flex-col gap-0">
                        <figure className="flex h-1 w-2 items-center justify-center">
                          <FaSortUp className="text-white" />
                        </figure>
                        <figure className="flex h-1 w-2 items-center justify-center">
                          <FaSortDown className="text-white" />
                        </figure>
                      </figure>
                    )} */}
                  </div>
                </Table.HeadCell>
              );
            })}
          </Table.Head>
          <Table.Body className="divide-y border-b border-b-[#71707180]">
            {employeeListData?.data?.map((res: any, index: number) => (
              <Table.Row key={index} className="text-dark h-[83px] w-full bg-white text-sm font-medium">
                <Table.Cell id="employee-info" className="whitespace-nowrap">
                  <div className="flex items-start gap-3">
                    <Avatar img={userDefaultAvatar} className="h-9 w-9 overflow-hidden rounded-full" />
                    <section id="employee-name-id">
                      <p>{`${res.nameEn} ${res.name}`}</p>
                      <p className="text-grey text-xs">{res.code}</p>
                    </section>
                    {res.gender && (
                      <Badge className="h-6 w-fit rounded-[6px] text-[10px]" style={renderGenderStyles(res.gender)}>
                        {t(`employee.${convertGender(res.gender)}`)}
                      </Badge>
                    )}
                  </div>
                </Table.Cell>
                <Table.Cell id="email">{res.email}</Table.Cell>
                <Table.Cell id="role">{res.position}</Table.Cell>
                <Table.Cell id="employee-type">{t(`employee.${res.employmentType.toLowerCase()}`)}</Table.Cell>
                <Table.Cell id="salary">{res.salary}</Table.Cell>
                <Table.Cell id="join-date">{formatDateFromHyphenToSlash(res.joinDate)}</Table.Cell>
                <Table.Cell id="action">
                  <div className="flex gap-3">
                    <Badge className="badge-purple">{t("common.view_details")}</Badge>
                    <Badge className="badge-red" onClick={() => handleDeleteState(res.id, res.name)}>
                      {t("common.delete")}
                    </Badge>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </main>
      <footer className="w-full">
        {employeeListData && (
          <Pagination
            currentPage={currentPage}
            totalPages={employeeListData.totalPages}
            totalCount={employeeListData.totalCount}
            currentLength={employeeListData.data.length}
            onPageChange={(page: number) => setCurrentPage(page)}
            onLimitChange={(limit: number) => setCurrentLimit(limit)}
          />
        )}
      </footer>
    </div>
  );
};
export default EmployeeManagement;

const theme = {
  root: {
    base: "w-full text-left text-sm text-gray-500",
    shadow: "absolute left-0 top-0 -z-10 h-full w-full rounded-lg bg-white drop-shadow-md",
    wrapper: "relative",
  },
  body: {
    base: "group/body",
    cell: {
      base: "px-6 py-4 group-first/body:group-first/row:first:rounded-tl-lg group-first/body:group-first/row:last:rounded-tr-lg group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg",
    },
  },
  head: {
    base: "group/head text-xs text-gray-700",
    cell: {
      base: "bg-gray-50 px-6 py-3 group-first/head:first:rounded-tl-xl group-first/head:first:rounded-bl-xl group-first/head:last:rounded-tr-xl group-first/head:last:rounded-br-xl",
    },
  },
  row: {
    base: "group/row",
    hovered: "hover:bg-gray-50",
    striped: "odd:bg-white even:bg-gray-50",
  },
};
