// Components
import TitleButton from "@/components/buttons/TitleButton";
import RoundButton from "@/components/buttons/RoundButton";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { FloatingLabel } from "flowbite-react";
import SingleSelectWithSearchDropdown from "@/components/dropdowns/SingleSelectWithSearchDropdown";
import SingleSelectDropdown from "@/components/dropdowns/SingleSelectDropdown";
import FloatingDatePicker from "@/components/pickers/FloatingDatePicker";
import ErrorMessage from "@/components/utils/ErrorMessage";

// Icons
import { ReactComponent as SaveIcon } from "@/assets/icons/svg/saveIcon.svg";

// Routing
import { useNavigate, useParams } from "react-router-dom";

// Themes
import { floatingLabelTheme } from "@/flowbiteThemes/floatingLabelTheme";

// React Hooks
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Types and Enums
import { LeaveStatus, LeaveType } from "@/types/enums";
import { CreateLeave } from "@/types/leave";

// API and Store
import { useGetEmployeeList } from "@/api/employee/employee.store";
import { useCreateLeave } from "@/api/leave/leave.store";

// Utils
import { showToast } from "@/components/toasts/toast";
import { handleError } from "@/utils/functions";

const feLeaveTypeData = [
  LeaveType.ANNUAL_LEAVE,
  LeaveType.NO_PAY_LEAVE,
  LeaveType.SICK_LEAVE,
  LeaveType.NO_PAY_SICK_LEAVE,
  LeaveType.MATERNITY_LEAVE,
  LeaveType.INJURY_LEAVE,
  LeaveType.BIRTHDAY_LEAVE,
  LeaveType.PERSONAL_LEAVE,
];

const feApprovalStatus = [LeaveStatus.PENDING, LeaveStatus.APPROVED, LeaveStatus.REJECTED, LeaveStatus.CANCELLED];

const CreateLeaveRecord = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  // fetch get employee list
  const [currentLimit, setCurrentLimit] = useState(20);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const {
    data: employeeListData,
    isLoading: isLoadingEmployeeList,
    isError: isErrorEmployeeList,
    error: errorEmployeeList,
  } = useGetEmployeeList(0, currentLimit, undefined, undefined, debouncedSearchValue);

  // handle get employee list error
  if (isErrorEmployeeList) {
    const errorMessage = handleError(errorEmployeeList);
    showToast.error(errorMessage);
  }

  // initial create leave body
  const initialCreateLeaveBody: CreateLeave = {
    employee: {
      id: null,
    },
    type: null,
    startDate: null,
    endDate: null,
    numOfDay: null,
    status: null,
    reason: null,
  };
  const [createLeaveBody, setCreateLeaveBody] = useState<CreateLeave>(initialCreateLeaveBody);

  // handle input change
  const handleInputChange = (key: string, value: any) => {
    setCreateLeaveBody((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // handle save
  const [errorMessage, setErrorMessage] = useState("");
  const { mutate: createLeave, isPending: isPendingCreateLeave } = useCreateLeave();
  const handleSave = () => {
    createLeave(createLeaveBody, {
      onSuccess: () => {
        showToast.success(t("common.success"));
        navigate(`/cms/${params.organisationCode}/leave`);
      },
      onError: (error) => {
        const errorMessage = handleError(error);
        showToast.error(errorMessage);
        setErrorMessage(errorMessage);
      },
    });
  };

  return (
    <div className="w-full">
      <TitleButton
        title={t("leave.create_leave_record")}
        onClick={() => navigate(`/cms/${params.organisationCode}/leave`)}
      />
      <header className="mt-6 flex w-full items-center justify-between text-sm font-semibold">
        <p className="text-dark">{t("leave.employee_information")}</p>
        <button>
          <RoundButton
            text={t("common.save")}
            onClick={handleSave}
            height="48px"
            bgColor="bg-secondary"
            textColor="text-dark"
            fontWeight="600"
            px="6"
            py="3"
            gap="12px"
            borderRadius="12px"
            iconLeft={<SVGIcon icon={SaveIcon} fill="#030623" width="24" height="24" />}
            disabled={isPendingCreateLeave}
          />
        </button>
      </header>
      <main id="CreateLeaveRecord-main" className="mt-6 w-full">
        <section id="employee_information-dropdown" className="mt-6 w-full">
          <SingleSelectWithSearchDropdown
            placeholder={t("leave.search_by_employee_name")}
            searchPlaceholder={t("leave.search_by_employee_name")}
            data={employeeListData?.data || []}
            totalCount={employeeListData?.totalCount || 0}
            onSelect={(item) => handleInputChange("employee", { id: item.id })}
            debouncedSearchValueFn={setDebouncedSearchValue}
            loadMoreDataFn={(limit) => setCurrentLimit(limit)}
            isLoading={isLoadingEmployeeList}
          />
        </section>

        <article id="leave-details-container">
          <p className="text-dark mt-6 text-sm font-semibold">{t("leave.leave_details")}</p>
          <div className="mt-6 w-full">
            <SingleSelectDropdown
              translationKey="leave"
              label="search_leave_type"
              data={feLeaveTypeData}
              onSelect={(item) => handleInputChange("type", item)}
              selectedValue={createLeaveBody.type || ""}
              withTranslate
            />
          </div>
          <section className="mt-6 flex w-full items-center gap-3">
            <FloatingDatePicker
              label={t("leave.start_date")}
              value={createLeaveBody.startDate}
              onChange={(value) => handleInputChange("startDate", value)}
              position="left"
            />
            <FloatingDatePicker
              label={t("leave.end_date")}
              value={createLeaveBody.endDate}
              onChange={(value) => handleInputChange("endDate", value)}
            />
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("leave.number_of_days")}
                theme={floatingLabelTheme}
                onChange={(e) => handleInputChange("numOfDay", Number(e.target.value))}
                value={createLeaveBody.numOfDay || ""}
              />
            </div>
          </section>

          <article id="approval_information-container">
            <p className="text-dark mt-6 text-sm font-semibold">{t("leave.approval_information")}</p>
            <section id="approval_information-dropdown" className="mt-6 w-full">
              <SingleSelectDropdown
                translationKey="leave"
                label="approval_status"
                data={feApprovalStatus}
                onSelect={(item) => handleInputChange("status", item)}
                selectedValue={createLeaveBody.status || ""}
                withTranslate
              />
            </section>
          </article>

          <article id="reason_for_leave-container">
            <p className="text-dark mt-6 text-sm font-semibold">{t("leave.reason_for_leave")}</p>
            <section className="mt-6 w-full">
              <div className="w-full">
                <FloatingLabel
                  variant="filled"
                  label={t("leave.reason")}
                  theme={floatingLabelTheme}
                  onChange={(e) => handleInputChange("reason", e.target.value)}
                  value={createLeaveBody.reason || ""}
                />
              </div>
            </section>
          </article>
          <section className="mt-6 w-full">
            <ErrorMessage message={errorMessage} />
          </section>
        </article>
      </main>
    </div>
  );
};

export default CreateLeaveRecord;
