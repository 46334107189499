import { useMutation, useQuery } from "@tanstack/react-query";
import { CreateEmployee, Salary, SortColumn, UpdateEmployee } from "@/types/employee";
import { SortOrder } from "@/types/util";
import { createEmployee, deleteEmployee, getEmployee, getEmployeeList, updateEmployee } from "./employee.api";

// create employee initial data
export const initialPositionItem = {
  name: null,
  type: null,
  workMode: null,
  department: null,
  startDate: null,
  endDate: null,
  workHour: null,
  changeType: null,
  changeReason: null,
  remark: null,
};

export const initialSalaryItem: Salary = {
  employee: {
    id: null,
  },
  amount: null,
  transportationAllowance: null,
  specialAllowance: null,
  payType: null,
  effectiveDate: null,
  changeType: null,
  changeReason: null,
  remark: null,
  mpfPercentageByEmployee: null,
  mpfPercentageByEmployer: null,
};

interface CommissionScheme {
  id: number;
}
export const initialCommissionSchemes: CommissionScheme[] = [];

export const initialCreateEmployeeBody = {
  code: null,
  name: null,
  nameEn: null,
  nameZh: null,
  hkid: null,
  bankAccount: null,
  phone: null,
  email: null,
  address: null,
  dob: null,
  gender: null,
  emergencyContactPerson: null,
  emergencyContactPhone: null,
  joinDate: null,
  annualLeave: null,
  sickLeave: null,
  commissionSchemes: initialCommissionSchemes,
  positions: [initialPositionItem],
  salaries: [initialSalaryItem],
};

// Create Employee
export const useCreateEmployee = () => {
  return useMutation({
    mutationFn: (data: CreateEmployee) => createEmployee(data),
  });
};

// Update Employee
export const useUpdateEmployee = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: UpdateEmployee }) => updateEmployee(id, data),
  });
};

// Delete Employee
export const useDeleteEmployee = () => {
  return useMutation({
    mutationFn: (id: number) => deleteEmployee(id),
  });
};

// Get Employee List
export const useGetEmployeeList = (
  page: number,
  limit: number,
  sortColumn?: SortColumn,
  sortOrder?: SortOrder,
  searchValue?: string
) => {
  return useQuery({
    queryKey: ["employeeList", { page, limit, sortColumn, sortOrder, searchValue }],
    queryFn: () => getEmployeeList(page, limit, sortColumn, sortOrder, searchValue).then((res) => res.data),
  });
};

// Get Employee
export const useGetEmployee = (id: number) => {
  return useQuery({
    queryKey: ["employee", id],
    queryFn: () => getEmployee(id).then((res) => res.data),
  });
};
