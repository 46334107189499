import { setDynamicColor } from "@/redux/setting/settingSlice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useEffect } from "react";

const ThemeProvider = ({ children }) => {
  const dispatch = useAppDispatch();
  const { primary, secondary } = useAppSelector((state) => state.setting.dynamicColor);

  const updateThemeColors = (newPrimary, newSecondary) => {
    // Update Redux state
    dispatch(setDynamicColor({ primary: newPrimary, secondary: newSecondary }));

    // Update CSS variables
    document.documentElement.style.setProperty("--primary-color", newPrimary);
    document.documentElement.style.setProperty("--secondary-color", newSecondary);
  };

  useEffect(() => {
    updateThemeColors("#030623", "#16E6ED");
  }, []);

  return <div>{children}</div>;
};

export default ThemeProvider;
