import { useMutation, useQuery } from "@tanstack/react-query";
import { SortColumn } from "@/types/employee";
import { SortOrder } from "@/types/util";
import { createScheme, deleteScheme, getCommissionSchemeList, updateScheme } from "./commissionScheme.api";
import { CommissionSchemeList, UpdateScheme } from "@/types/scheme";
import { CreateScheme } from "@/types/scheme";

// get commission scheme list
export const useGetCommissionSchemeList = (
  page: number,
  limit: number,
  sortColumn?: SortColumn,
  sortOrder?: SortOrder,
  searchValue?: string,
  cycle?: string[]
) => {
  return useQuery<CommissionSchemeList>({
    queryKey: ["commissionSchemeList", { page, limit, sortColumn, sortOrder, searchValue, cycle }],
    queryFn: () => getCommissionSchemeList(page, limit, sortColumn, sortOrder, searchValue, cycle).then((res) => res.data),
  });
};

// create commission scheme
export const useCreateCommissionScheme = () => {
  return useMutation({
    mutationFn: (data: CreateScheme) => createScheme(data),
  });
};

// update commission scheme
export const useUpdateCommissionScheme = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: UpdateScheme }) => updateScheme(id, data),
  });
};

// delete commission scheme
export const useDeleteCommissionScheme = () => {
  return useMutation({
    mutationFn: (id: number) => deleteScheme(id),
  });
};
