export const tableTheme = {
  root: {
    base: "w-full text-left text-sm text-gray-500",
    shadow: "absolute left-0 top-0 -z-10 h-full w-full rounded-lg bg-white drop-shadow-md",
    wrapper: "relative",
  },
  body: {
    base: "group/body",
    cell: {
      base: "px-6 py-4 group-first/body:group-first/row:first:rounded-tl-lg group-first/body:group-first/row:last:rounded-tr-lg group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg",
    },
  },
  head: {
    base: "group/head text-xs text-gray-700",
    cell: {
      base: "bg-gray-50 px-6 py-3 group-first/head:first:rounded-tl-xl group-first/head:first:rounded-bl-xl group-first/head:last:rounded-tr-xl group-first/head:last:rounded-br-xl",
    },
  },
  row: {
    base: "group/row",
    hovered: "hover:bg-gray-50",
    striped: "odd:bg-white even:bg-gray-50",
  },
};
