// External dependencies
import { FloatingLabel } from "flowbite-react";
import { useState, useRef, useEffect } from "react";

// Internal components
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import CustomDatePicker from "@/components/pickers/CustomDatePicker";

// Icons
import { ReactComponent as DatePickerIcon } from "@/assets/icons/svg/datePickerIcon.svg";

// Themes
import { floatingLabelTheme } from "@/flowbiteThemes/floatingLabelTheme";

// Utils
import { formatDateByHyphen, formatDateFromHyphenToSlash, formatYearMonthFromHyphenToSlash } from "@/utils/functions";

interface FloatingDatePickerProps {
  label: string;
  value: string | null;
  onChange: (value: string) => void;
  isDob?: boolean;
  position?: "left" | "right";
  byYearMonth?: boolean;
}

const FloatingDatePicker = ({
  label,
  value,
  onChange,
  isDob = false,
  position = "right",
  byYearMonth = false,
}: FloatingDatePickerProps) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const datePickerRef = useRef<HTMLDivElement>(null);

  // Handle date selection
  const handleDateSelect = (date: Date) => {
    const formattedDate = formatDateByHyphen(date);
    if (formattedDate) {
      onChange(formattedDate);
    }
    setIsDatePickerOpen(false);
  };

  // Handle clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
        setIsDatePickerOpen(false);
        setIsFocused(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle container click
  const handleContainerClick = () => {
    setIsDatePickerOpen(true);
    setIsFocused(true);
  };

  return (
    <div className="relative w-full" ref={datePickerRef}>
      <div onClick={handleContainerClick}>
        <FloatingLabel
          variant="filled"
          label={label}
          theme={floatingLabelTheme}
          className="cursor-pointer"
          readOnly
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          value={byYearMonth ? formatYearMonthFromHyphenToSlash(value) || "" : formatDateFromHyphenToSlash(value) || ""}
        />
        <figure className="hover-scale-sm absolute right-[12px] top-[25%] cursor-pointer">
          <SVGIcon icon={DatePickerIcon} fill={isFocused ? "#16E6ED" : "#717071B3"} width="24" height="24" />
        </figure>
      </div>
      {isDatePickerOpen && (
        <figure className={`absolute ${position === "left" ? "left-0" : "right-0"} top-12 z-50`}>
          <CustomDatePicker
            onSelectedDate={(date: Date | null) => date && handleDateSelect(date)}
            onClose={() => setIsDatePickerOpen(false)}
            isDob={isDob}
            byYearMonth={byYearMonth}
          />
        </figure>
      )}
    </div>
  );
};

export default FloatingDatePicker;
