import { api } from "@/api/apiClient";
import { SortColumn } from "@/types/employee";
import { HolidayType } from "@/types/enums";
import { CreateHoliday, UpdateHoliday } from "@/types/holiday";
import { SortOrder } from "@/types/util";

export const getHoliday = (id: number) => {
  return api.get(`/holiday/${id}`);
};

export const getHolidayList = (
  page: number,
  limit: number,
  sortColumn?: SortColumn,
  sortOrder?: SortOrder,
  searchValue?: string,
  type?: HolidayType,
  yearMonth?: string,
  startDate?: string,
  endDate?: string
) => {
  return api.get(`/holiday`, {
    params: {
      page,
      limit,
      sortColumn,
      sortOrder,
      searchValue,
      type,
      yearMonth,
      startDate,
      endDate,
    },
  });
};

export const createHoliday = (data: CreateHoliday) => {
  return api.post(`/holiday`, data);
};

export const updateHoliday = (id: number, data: UpdateHoliday) => {
  return api.patch(`/holiday/${id}`, data);
};

export const deleteHoliday = (id: number) => {
  return api.delete(`/holiday/${id}`);
};
