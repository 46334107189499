import { modalTheme } from "@/flowbiteThemes/modalTheme";
import { Modal } from "flowbite-react";
import React, { ReactNode } from "react";
import { HiOutlineX } from "react-icons/hi";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "7xl";
  children: ReactNode;
};

const ConfirmationModal = ({ isOpen, onClose, title, size, children }: Props) => {
  return (
    <div>
      <Modal
        show={isOpen}
        onClose={onClose}
        theme={modalTheme}
        dismissible
        size={size ?? "lg"}
        className="font-dm-sans"
      >
        <Modal.Body>
          <header className="flex w-full items-center">
            <p className="text-dark w-full text-2xl font-bold">{title}</p>
            <HiOutlineX className="hover-scale-sm h-9 w-9 cursor-pointer text-[#717071]" onClick={onClose} />
          </header>
          <main className="mt-6">{children}</main>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
