// UI Components
import TitleButton from "@/components/buttons/TitleButton";
import RoundButton from "@/components/buttons/RoundButton";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { FloatingLabel } from "flowbite-react";
import LoadingSpinner from "@/components/utils/LoadingSpinner";
import { showToast } from "@/components/toasts/toast";
import SingleSelectDropdown from "@/components/dropdowns/SingleSelectDropdown";
import MultiCheckSearchDropdown from "@/components/dropdowns/MultiCheckSearchDropdown";
import FloatingDatePicker from "@/components/pickers/FloatingDatePicker";

// Icons
import { ReactComponent as BackIcon } from "@/assets/icons/svg/backIcon.svg";
import { ReactComponent as NextIcon } from "@/assets/icons/svg/nextIcon.svg";
import { ReactComponent as SaveIcon } from "@/assets/icons/svg/saveIcon.svg";
import { ReactComponent as CircleAddIcon } from "@/assets/icons/svg/circleAddIcon.svg";

// Routing
import { useLocation, useNavigate, useParams } from "react-router-dom";

// Themes
import { floatingLabelTheme } from "@/flowbiteThemes/floatingLabelTheme";

// React Hooks
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Utils
import { getOrganisationCode, handleError } from "@/utils/functions";

// Types and Enums
import { EmploymentType, Gender, PayType, PositionChangeType, PositionWorkMode, SalaryChangeType } from "@/types/enums";

// API and Store
import {
  initialCreateEmployeeBody,
  initialPositionItem,
  initialSalaryItem,
  useCreateEmployee,
} from "@/api/employee/employee.store";
import { useGetCommissionSchemeList } from "@/api/scheme/commissionScheme.store";

// frontend data
const feGenderData = [Gender.MALE, Gender.FEMALE, Gender.OTHER];
const feEmploymentType = [EmploymentType.FULL_TIME, EmploymentType.PART_TIME, EmploymentType.INTERN];
const feWorkMode = [PositionWorkMode.MON_FRI, PositionWorkMode.CUSTOM];
const fePositionChangeType = [PositionChangeType.PROMOTION, PositionChangeType.TRANSFER];
const feSalaryPayType = [PayType.ANNUALLY, PayType.MONTHLY, PayType.WEEKLY, PayType.DAILY, PayType.HOURLY];
const feSalaryChangeType = [SalaryChangeType.NORMAL, SalaryChangeType.PROMOTION];

const CreateEmployee = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const searchParams = new URLSearchParams(location.search);
  const queryParamsByTab = searchParams.get("tab");

  const [createEmployeeData, setCreateEmployeeData] = useState(initialCreateEmployeeBody);

  // fetch create employee
  const { mutate: createEmployee, isPending: isCreateEmployeePending } = useCreateEmployee();
  const handleCreateEmployee = () => {
    createEmployee(createEmployeeData, {
      onSuccess: () => {
        showToast.success(t("common.success"));
        navigate(`/cms/${getOrganisationCode()}/employee-management`);
      },
      onError: (error) => {
        const errorMessage = handleError(error);
        showToast.error(errorMessage);
      },
    });
  };

  // handle tabs and back / next button
  const tabData = ["personal_details", "position", "salary", "commission_scheme"];
  const handleNext = () => {
    const tabMapping: Record<string, string> = {
      personal_details: "position",
      position: "salary",
      salary: "commission_scheme",
    };

    if (queryParamsByTab && tabMapping[queryParamsByTab]) {
      const nextTab = tabMapping[queryParamsByTab];
      searchParams.set("tab", nextTab);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  };
  const handleBack = () => {
    const tabMapping: Record<string, string> = {
      position: "personal_details",
      salary: "position",
      commission_scheme: "salary",
    };

    if (queryParamsByTab && tabMapping[queryParamsByTab]) {
      const nextTab = tabMapping[queryParamsByTab];
      searchParams.set("tab", nextTab);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  };

  // normal input change
  const handleInputChange = (value: any, name: string) => {
    setCreateEmployeeData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // position items
  const onAddPositionItemClick = () => {
    setCreateEmployeeData((prevState) => ({
      ...prevState,
      positions: [...prevState.positions, initialPositionItem],
    }));
  };
  const onRemovePositionItemClick = (index: number) => {
    setCreateEmployeeData((prevState) => ({
      ...prevState,
      positions: prevState.positions.filter((_, i) => i !== index),
    }));
  };
  const handlePositionValueChange = (index: number, field: string, value: string | number | null) => {
    const updatedPositions = createEmployeeData.positions.map((position, i) => {
      if (i === index) {
        return { ...position, [field]: value };
      }
      return position;
    });
    setCreateEmployeeData({
      ...createEmployeeData,
      positions: updatedPositions,
    });
  };

  // salary items
  const onAddSalaryItemClick = () => {
    setCreateEmployeeData((prevState) => ({
      ...prevState,
      salaries: [...prevState.salaries, initialSalaryItem],
    }));
  };
  const onRemoveSalaryItemClick = (index: number) => {
    setCreateEmployeeData((prevState) => ({
      ...prevState,
      salaries: prevState.salaries.filter((_, i) => i !== index),
    }));
  };
  const handleSalaryValueChange = (index: number, field: string, value: string | number | null) => {
    const updatedSalaries = createEmployeeData.salaries.map((salary, i) => {
      if (i === index) {
        return { ...salary, [field]: value };
      }
      return salary;
    });
    setCreateEmployeeData({
      ...createEmployeeData,
      salaries: updatedSalaries,
    });
  };

  // handle commission scheme list
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>("");
  const [limit, setLimit] = useState(20);

  // Fetch commission schemes
  const {
    data: commissionSchemeListData,
    isLoading: isCommissionSchemeListLoading,
    isError: isCommissionSchemeListError,
    error: commissionSchemeListError,
  } = useGetCommissionSchemeList(0, limit, undefined, undefined, debouncedSearchValue);

  useEffect(() => {
    if (isCommissionSchemeListError) {
      const errorMessage = handleError(commissionSchemeListError);
      showToast.error(errorMessage);
    }
  }, [isCommissionSchemeListError]);

  // handle HKID validation
  function validateHKID(hkid: string) {
    const regex = /^[A-Z]{1,2}\d{6}\([0-9A]\)$/;
    const cleanedHKID = hkid.trim().toUpperCase();
    return regex.test(cleanedHKID);
  }

  return (
    <div className="w-full">
      <TitleButton
        title={t("employee.create_employee")}
        onClick={() => navigate(`/cms/${params.organisationCode}/employee-management`)}
      />

      <header className="mt-6 flex w-full items-center justify-between">
        <section id="CreateEmployee-tabs" className="flex w-full items-center gap-6">
          {tabData.map((tab, index) => {
            return (
              <figure
                key={index}
                className={`w-fit py-3 text-sm font-semibold 
                  ${
                    queryParamsByTab === tab
                      ? "cursor-pointer border-b-2 border-secondary text-primary"
                      : "border-lightgrey cursor-default text-[#71707180] hover:border-b-2"
                  } `}
              >
                {t(`employee.${tab}`)}
              </figure>
            );
          })}
        </section>
        <section id="CreateEmployee-action-buttons" className="flex w-fit items-center gap-3">
          {queryParamsByTab === "commission_scheme" && (
            <RoundButton
              text={t("common.save")}
              onClick={handleCreateEmployee}
              height="48px"
              bgColor="bg-secondary"
              textColor="text-dark"
              fontWeight="600"
              px="6"
              py="3"
              gap="12px"
              borderRadius="12px"
              iconLeft={<SVGIcon icon={SaveIcon} fill="#030623" width="24" height="24" />}
              disabled={isCreateEmployeePending}
            />
          )}
          <RoundButton
            text={t("common.back")}
            onClick={handleBack}
            height="48px"
            bgColor="bg-secondary"
            textColor="text-dark"
            fontWeight="600"
            px="6"
            py="3"
            gap="12px"
            borderRadius="12px"
            iconLeft={<SVGIcon icon={BackIcon} fill="#030623" width="24" height="24" />}
            disabled={queryParamsByTab === "personal_details" || isCreateEmployeePending}
          />
          <RoundButton
            text={t("common.next")}
            onClick={handleNext}
            height="48px"
            bgColor="bg-secondary"
            textColor="text-dark"
            fontWeight="600"
            px="6"
            py="3"
            gap="12px"
            borderRadius="12px"
            iconLeft={<SVGIcon icon={NextIcon} fill="#030623" width="24" height="24" />}
            disabled={queryParamsByTab === "commission_scheme"}
          />
        </section>
      </header>
      {queryParamsByTab === "personal_details" && (
        <main id="CreateEmployee-main personal-details" className="mt-9 flex w-full flex-col gap-6">
          <section className="flex w-full items-center gap-3">
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.display_name")}
                theme={floatingLabelTheme}
                className="min-w-[272px]"
                onChange={(e) => handleInputChange(e.target.value, "name")}
                value={createEmployeeData.name || ""}
              />
            </div>
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.name_zh")}
                theme={floatingLabelTheme}
                className="min-w-[272px]"
                onChange={(e) => handleInputChange(e.target.value, "nameZh")}
                value={createEmployeeData.nameZh || ""}
              />
            </div>
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.name_en")}
                theme={floatingLabelTheme}
                className="min-w-[272px]"
                onChange={(e) => handleInputChange(e.target.value, "nameEn")}
                value={createEmployeeData.nameEn || ""}
              />
            </div>
          </section>
          <section className="flex w-full items-center gap-3">
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.employee_or_contract_id")}
                theme={floatingLabelTheme}
                className="min-w-[272px]"
                onChange={(e) => handleInputChange(e.target.value, "code")}
                value={createEmployeeData.code || ""}
              />
            </div>
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.hkid")}
                theme={floatingLabelTheme}
                className="min-w-[272px]"
                onChange={(e) => handleInputChange(e.target.value, "hkid")}
                value={createEmployeeData.hkid || ""}
              />
            </div>
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.bank_account_number")}
                theme={floatingLabelTheme}
                className="min-w-[272px]"
                onChange={(e) => handleInputChange(e.target.value, "bankAccount")}
                value={createEmployeeData.bankAccount || ""}
              />
            </div>
          </section>
          <section className="flex w-full items-center gap-3">
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.contact_number")}
                theme={floatingLabelTheme}
                onChange={(e) => handleInputChange(e.target.value, "phone")}
                value={createEmployeeData.phone || ""}
              />
            </div>
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.email_address")}
                theme={floatingLabelTheme}
                onChange={(e) => handleInputChange(e.target.value, "email")}
                value={createEmployeeData.email || ""}
              />
            </div>
          </section>
          <section className="w-full">
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.address")}
                theme={floatingLabelTheme}
                onChange={(e) => handleInputChange(e.target.value, "address")}
                value={createEmployeeData.address || ""}
              />
            </div>
          </section>
          <section className="flex w-full items-center gap-3">
            <FloatingDatePicker
              label={t("employee.date_of_birth")}
              value={createEmployeeData.dob}
              onChange={(value) => handleInputChange(value, "dob")}
              isDob
              position="left"
            />
            <SingleSelectDropdown
              translationKey="employee"
              label="gender"
              data={feGenderData}
              onSelect={(value) => handleInputChange(value, "gender")}
              selectedValue={createEmployeeData.gender || ""}
              withTranslate
            />
            <FloatingDatePicker
              label={t("employee.join_date")}
              value={createEmployeeData.joinDate}
              onChange={(value) => handleInputChange(value, "joinDate")}
            />
          </section>
          <section className="flex w-full items-center gap-3">
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.emergency_contact_name")}
                theme={floatingLabelTheme}
                value={createEmployeeData.emergencyContactPerson || ""}
                onChange={(e) => handleInputChange(e.target.value, "emergencyContactPerson")}
              />
            </div>
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("employee.emergency_contact_number")}
                theme={floatingLabelTheme}
                value={createEmployeeData.emergencyContactPhone || ""}
                onChange={(e) => handleInputChange(e.target.value, "emergencyContactPhone")}
              />
            </div>
          </section>
        </main>
      )}
      {queryParamsByTab === "position" && (
        <main id="CreateEmployee-main position">
          {createEmployeeData.positions.map((position, index) => {
            return (
              <section
                id={`position ${index + 1}`}
                className="flex w-full flex-col gap-6 border-b-[1px] border-[#71707180] pb-9 pt-4"
                key={index}
              >
                {createEmployeeData.positions.length > 1 && (
                  <div className="flex w-full justify-end">
                    <figure className="rotate-45 cursor-pointer" onClick={() => onRemovePositionItemClick(index)}>
                      <SVGIcon icon={CircleAddIcon} fill="#16E6ED" width="24" height="24" />
                    </figure>
                  </div>
                )}
                <section className="flex w-full items-center gap-3">
                  <SingleSelectDropdown
                    translationKey="employee"
                    label="employment_type"
                    data={feEmploymentType}
                    onSelect={(value) => handlePositionValueChange(index, "type", value)}
                    selectedValue={position.type || ""}
                    withTranslate
                  />
                  <SingleSelectDropdown
                    translationKey="employee"
                    label="work_mode"
                    data={feWorkMode}
                    onSelect={(value) => handlePositionValueChange(index, "workMode", value)}
                    selectedValue={position.workMode || ""}
                    withTranslate
                  />
                </section>
                <section className="flex w-full items-center gap-3">
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.department")}
                      theme={floatingLabelTheme}
                      value={position.department || ""}
                      onChange={(e) => handlePositionValueChange(index, "department", e.target.value)}
                    />
                  </div>
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.position")}
                      theme={floatingLabelTheme}
                      value={position.name || ""}
                      onChange={(e) => handlePositionValueChange(index, "name", e.target.value)}
                    />
                  </div>
                </section>
                <section className="flex w-full items-center gap-3">
                  <FloatingDatePicker
                    label={t("employee.start_date")}
                    value={position.startDate}
                    onChange={(value) => handlePositionValueChange(index, "startDate", value)}
                  />
                  <FloatingDatePicker
                    label={t("employee.end_date")}
                    value={position.endDate}
                    onChange={(value) => handlePositionValueChange(index, "endDate", value)}
                  />
                </section>
                <section className="flex w-full items-center gap-3">
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.working_hour")}
                      theme={floatingLabelTheme}
                      value={position.workHour ?? ""}
                      onChange={(e) =>
                        handlePositionValueChange(
                          index,
                          "workHour",
                          !e.target.value || isNaN(Number(e.target.value)) ? null : Number(e.target.value)
                        )
                      }
                    />
                  </div>
                  <SingleSelectDropdown
                    translationKey="employee"
                    label="change_type"
                    data={fePositionChangeType}
                    onSelect={(value) => handlePositionValueChange(index, "changeType", value)}
                    selectedValue={position.changeType || ""}
                    withTranslate
                  />
                </section>
                <section className="flex w-full items-center gap-3">
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.reason_for_change")}
                      theme={floatingLabelTheme}
                      value={position.changeReason ?? ""}
                      onChange={(e) => handlePositionValueChange(index, "changeReason", e.target.value)}
                    />
                  </div>
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.remark")}
                      theme={floatingLabelTheme}
                      value={position.remark ?? ""}
                      onChange={(e) => handlePositionValueChange(index, "remark", e.target.value)}
                    />
                  </div>
                </section>
              </section>
            );
          })}

          <button
            id="add-position-btn-container"
            className="hover-scale-sm mt-9 flex h-12 w-full items-center justify-center gap-3 rounded-xl bg-[#16E6ED1A] px-6 py-3"
            onClick={onAddPositionItemClick}
          >
            <SVGIcon icon={CircleAddIcon} fill="#16E6ED" width="24" height="24" />
            <p className="text-sm font-semibold text-[#16E6ED]">{t("employee.add_position")}</p>
          </button>
        </main>
      )}
      {queryParamsByTab === "salary" && (
        <main id="CreateEmployee-main salary">
          {createEmployeeData.salaries.map((salary, index) => {
            return (
              <section
                id={`salary ${index + 1}`}
                className="flex w-full flex-col gap-6 border-b-[1px] border-[#71707180] pb-9 pt-4"
                key={index}
              >
                {createEmployeeData.salaries.length > 1 && (
                  <div className="flex w-full justify-end">
                    <figure className="rotate-45 cursor-pointer" onClick={() => onRemoveSalaryItemClick(index)}>
                      <SVGIcon icon={CircleAddIcon} fill="#16E6ED" width="24" height="24" />
                    </figure>
                  </div>
                )}
                <section className="flex w-full items-center gap-3">
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.salary")}
                      theme={floatingLabelTheme}
                      value={salary.amount ?? ""}
                      onChange={(e) =>
                        handleSalaryValueChange(
                          index,
                          "amount",
                          !e.target.value || isNaN(Number(e.target.value)) ? null : Number(e.target.value)
                        )
                      }
                    />
                  </div>
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.transportation_allowance")}
                      theme={floatingLabelTheme}
                      value={salary.transportationAllowance ?? ""}
                      onChange={(e) =>
                        handleSalaryValueChange(
                          index,
                          "transportationAllowance",
                          !e.target.value || isNaN(Number(e.target.value)) ? null : Number(e.target.value)
                        )
                      }
                    />
                  </div>
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.special_allowance")}
                      theme={floatingLabelTheme}
                      value={salary.specialAllowance ?? ""}
                      onChange={(e) =>
                        handleSalaryValueChange(
                          index,
                          "specialAllowance",
                          !e.target.value || isNaN(Number(e.target.value)) ? null : Number(e.target.value)
                        )
                      }
                    />
                  </div>
                </section>
                <section className="flex w-full items-center gap-3">
                  <SingleSelectDropdown
                    translationKey="employee"
                    label="pay_type"
                    data={feSalaryPayType}
                    onSelect={(value) => handleSalaryValueChange(index, "payType", value)}
                    selectedValue={salary.payType || ""}
                    withTranslate
                  />
                  <FloatingDatePicker
                    label={t("employee.effective_date")}
                    value={salary.effectiveDate}
                    onChange={(value) => handleSalaryValueChange(index, "effectiveDate", value)}
                  />
                </section>
                <section className="flex w-full items-center gap-3">
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.employee_mpf_percentage")}
                      theme={floatingLabelTheme}
                      value={salary.mpfPercentageByEmployee ?? ""}
                      onChange={(e) =>
                        handleSalaryValueChange(
                          index,
                          "mpfPercentageByEmployee",
                          !e.target.value || isNaN(Number(e.target.value)) ? null : Number(e.target.value)
                        )
                      }
                    />
                  </div>
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.employer_mpf_percentage")}
                      theme={floatingLabelTheme}
                      value={salary.mpfPercentageByEmployer ?? ""}
                      onChange={(e) =>
                        handleSalaryValueChange(
                          index,
                          "mpfPercentageByEmployer",
                          !e.target.value || isNaN(Number(e.target.value)) ? null : Number(e.target.value)
                        )
                      }
                    />
                  </div>
                </section>
                <section className="flex w-full items-center gap-3">
                  <SingleSelectDropdown
                    translationKey="employee"
                    label="salary_change_type"
                    data={feSalaryChangeType}
                    onSelect={(value) => handleSalaryValueChange(index, "changeType", value)}
                    selectedValue={salary.changeType || ""}
                    withTranslate
                  />
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.reason_for_change")}
                      theme={floatingLabelTheme}
                      value={salary.changeReason ?? ""}
                      onChange={(e) => handleSalaryValueChange(index, "changeReason", e.target.value)}
                    />
                  </div>
                </section>
                <section className="flex w-full items-center gap-3">
                  <div className="w-full">
                    <FloatingLabel
                      variant="filled"
                      label={t("employee.remark")}
                      theme={floatingLabelTheme}
                      value={salary.remark ?? ""}
                      onChange={(e) => handleSalaryValueChange(index, "remark", e.target.value)}
                    />
                  </div>
                </section>
              </section>
            );
          })}
          <button
            id="add-salary-btn-container"
            className="hover-scale-sm mt-9 flex h-12 w-full items-center justify-center gap-3 rounded-xl bg-[#16E6ED1A] px-6 py-3"
            onClick={onAddSalaryItemClick}
          >
            <SVGIcon icon={CircleAddIcon} fill="#16E6ED" width="24" height="24" />
            <p className="text-sm font-semibold text-[#16E6ED]">{t("employee.add_salary")}</p>
          </button>
        </main>
      )}
      {queryParamsByTab === "commission_scheme" && (
        <main id="CreateEmployee-main commission_scheme" className="relative mt-9 flex w-full flex-col gap-6">
          {isCreateEmployeePending && <LoadingSpinner />}
          <p className="text-dark w-full text-sm font-semibold">{t("employee.commission_scheme")}</p>

          <section className="w-full">
            <MultiCheckSearchDropdown
              placeholder={t("employee.select_commission_scheme")}
              searchPlaceholder={t("employee.search_by_commission_scheme")}
              data={commissionSchemeListData?.data ?? []}
              totalCount={commissionSchemeListData?.totalCount ?? 0}
              onSelect={(item) => setCreateEmployeeData((prev) => ({ ...prev, commissionSchemes: item }))}
              debouncedSearchValueFn={(value) => setDebouncedSearchValue(value)}
              isLoading={isCommissionSchemeListLoading}
              loadMoreDataFn={(limit) => setLimit(limit)}
            />
          </section>
        </main>
      )}
    </div>
  );
};

export default CreateEmployee;
