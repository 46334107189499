// React imports
import { useEffect, useState } from "react";

// Utility functions
import { capitalizeFirstLetter, handleError } from "@/utils/functions";

// Components
import ErrorMessage from "@/components/utils/ErrorMessage";
import { Checkbox, Label } from "flowbite-react";
import LoadingSpinner from "@/components/utils/LoadingSpinner";

// Routing
import { useNavigate, useParams } from "react-router-dom";

// Translation
import { useTranslation } from "react-i18next";

// Auth & Storage
import { setToken, getToken } from "@/utils/localStorage";
import { useLogin } from "@/api/auth/auth.store";

// Toast notifications
import { showToast } from "@/components/toasts/toast";

type UserInputValue = {
  username: string;
  password: string;
};

const Login = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const [isRememberMe, setIsRememberMe] = useState(true);
  const [errorMessage, setErrorMessage] = useState<any>("");

  // handle input change
  const [userInputValue, setUserInputValue] = useState<UserInputValue>({ username: "", password: "" });
  const handleUserInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInputValue({
      ...userInputValue,
      [e.target.name]: e.target.value,
    });
  };

  // login mutation
  const { mutate: loginMutation, isPending: isPendingLogin } = useLogin();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    loginMutation(userInputValue, {
      onSuccess: (response) => {
        if (response?.accessToken && params.organisationCode) {
          setToken(response.accessToken);
          navigate(`/cms/${params.organisationCode}/dashboard`);
        }
      },
      onError: (error: any) => {
        const errorMessage = handleError(error);
        showToast.error(errorMessage);
        setErrorMessage(errorMessage);
      },
    });
  };

  useEffect(() => {
    const token = getToken();
    if (token) {
      navigate(`/cms/${params.organisationCode}/dashboard`);
    }
  }, []);

  return (
    <div className="relative flex h-[100vh] w-full items-center justify-center">
      {isPendingLogin && <LoadingSpinner />}
      <form onSubmit={handleSubmit}>
        <main className="h-fit w-[700px] border border-primary p-8">
          <header className="text-dark flex w-full items-center justify-center">
            <h1>{`${capitalizeFirstLetter(params.organisationCode)} ${t("common.management_system")}`}</h1>
          </header>
          <section className="mt-8 flex w-full flex-col gap-8">
            <div className="flex items-center gap-3">
              <label className="text-dark min-w-[100px]">{t("auth.username")}</label>
              <input
                type="text"
                id="username"
                name="username"
                value={userInputValue.username}
                onChange={handleUserInputChange}
                className="w-full focus:border-primary focus:outline-none"
              />
            </div>
            <div className="flex items-center gap-3">
              <label className="text-dark min-w-[100px]">{t("auth.password")}</label>
              <input
                type="password"
                id="password"
                name="password"
                value={userInputValue.password}
                onChange={handleUserInputChange}
                className="w-full focus:border-primary focus:outline-none"
              />
            </div>
          </section>
          <div className="mt-8 flex items-center gap-2">
            <Checkbox
              id="remember"
              theme={checkboxTheme}
              defaultChecked
              onChange={(e) => setIsRememberMe(e.target.checked)}
            />
            <Label htmlFor="remember" className="text-dark">
              {t("auth.remember_me")}
            </Label>
          </div>
          <button type="submit" className="mt-[24px] w-full bg-primary py-2 text-white hover:opacity-75">
            {t("common.submit")}
          </button>
          <ErrorMessage message={errorMessage} />
        </main>
      </form>
    </div>
  );
};

export default Login;

const checkboxTheme = {
  root: {
    base: "h-4 w-4 rounded border border-gray-300 bg-gray-100 focus:ring-0 focus:outline-none",
    color: {
      default: "text-primary ",
    },
  },
};
