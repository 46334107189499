import { toast, ToastOptions, Bounce } from "react-toastify";

const defaultToastConfig: ToastOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  transition: Bounce,
  closeButton: false,
  bodyClassName: "text-dark font-dm-sans",
};

export const showToast = {
  success: (message: string | string[], options?: ToastOptions) => {
    const formattedMessage = Array.isArray(message) ? message.map((msg) => `• ${msg}`).join("\n") : message;
    toast.success(formattedMessage, { ...defaultToastConfig, ...options });
  },
  error: (message: string | string[], options?: ToastOptions) => {
    const formattedMessage = Array.isArray(message) ? message.map((msg) => `• ${msg}`).join("\n") : message;
    toast.error(formattedMessage, { ...defaultToastConfig, ...options });
  },
};
