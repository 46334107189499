// UI Components
import RoundButton from "@/components/buttons/RoundButton";
import SearchInput from "@/components/inputs/SearchInput";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { Badge, Table } from "flowbite-react";
import Pagination from "@/components/utils/Pagination";
import LoadingSpinner from "@/components/utils/LoadingSpinner";
import ConfirmationModal from "@/components/modals/ConfirmationModal";

// Icons & Assets
import { ReactComponent as FilterIcon } from "@/assets/icons/svg/filterIcon.svg";
import { ReactComponent as CircleArrowRightIcon } from "@/assets/icons/svg/arrows/circleArrow/circleArrowRightIcon.svg";

// Hooks
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";

// API and Utils
import { useDeleteLeave, useGetLeaveList } from "@/api/leave/leave.store";
import { showToast } from "@/components/toasts/toast";
import { formatDateFromHyphenToSlash, formatDateTimeBySlash, handleError } from "@/utils/functions";
import { tableTheme } from "@/flowbiteThemes/tableTheme";

// Types
import { LeaveStatus, LeaveType } from "@/types/enums";
import { SortOrder } from "@/types/util";
import { SortColumn } from "@/types/employee";
import { LeaveListItem } from "@/types/leave";

const LeaveRecord = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  // fetch leave record list
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(10);
  const [sortColumn, setSortColumn] = useState<SortColumn>("");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>("");
  const {
    data: leaveRecordListData,
    isLoading: isLeaveRecordListLoading,
    isError: isLeaveRecordListError,
    error: errorLeaveRecordList,
    refetch: refetchLeaveRecordList,
  } = useGetLeaveList(currentPage - 1, currentLimit, undefined, undefined, debouncedSearchValue);

  // handle error
  if (isLeaveRecordListError) {
    showToast.error(errorLeaveRecordList.message || t("common.error"));
  }

  const tableHeadCells = [
    "employee_name",
    "leave_type",
    "start_date",
    "end_date",
    "number_of_days",
    "approval_status",
    "create_time",
    "action",
  ];

  // fetch delete commission scheme
  const { mutate: deleteLeave } = useDeleteLeave();

  const handleDeleteLeave = (id: number | null) => {
    if (!id) return;
    deleteLeave(id, {
      onSuccess: () => {
        showToast.success(t("common.success"));
        setIsDeleteModalOpen(false);
        refetchLeaveRecordList();
      },
      onError: (error) => {
        const errorMessage = handleError(error);
        showToast.error(errorMessage);
      },
    });
  };

  // delete confirmation modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<number | null>(null);
  const [selectedDeleteTitle, setSelectedDeleteTitle] = useState("");

  const handleDeleteState = (id: number | null, title: string) => {
    setSelectedDeleteId(id);
    setSelectedDeleteTitle(title);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModal = () => (
    <ConfirmationModal
      isOpen={isDeleteModalOpen}
      onClose={() => setIsDeleteModalOpen(false)}
      title={t("common.delete_confirmation")}
    >
      <div className="text-dark w-full">
        <header className="w-full text-base font-semibold">
          {t("common.delete_confirmation_message", { item: selectedDeleteTitle })}
        </header>
        <button className="confirm-modal-btn" onClick={() => handleDeleteLeave(selectedDeleteId)}>
          {t("common.delete")}
        </button>
      </div>
    </ConfirmationModal>
  );

  //handle leave type badge styles
  const handleLeaveTypeBadgeStyles = (type: LeaveType) => {
    const typeStylesMapping = {
      ANNUAL_LEAVE: { backgroundColor: "#8338EC0D", color: "#8338EC" },
      NO_PAY_LEAVE: { backgroundColor: "#213A571A", color: "#213A57" },
      SICK_LEAVE: { backgroundColor: "#2227F70D", color: "#2227F7" },
      NO_PAY_SICK_LEAVE: { backgroundColor: "#9D65221A", color: "#9D6522" },
      MATERNITY_LEAVE: { backgroundColor: "#FF006E0D", color: "#FF006E" },
      INJURY_LEAVE: { backgroundColor: "#FF33330D", color: "#FF3333" },
      BIRTHDAY_LEAVE: { backgroundColor: "#0AD1C81A", color: "#0AD1C8" },
      PERSONAL_LEAVE: { backgroundColor: "#0AD1C81A", color: "#0AD1C8" },
    };
    return typeStylesMapping[type];
  };

  //handle approval status badge styles
  const handleApprovalStatusBadgeStyles = (status: LeaveStatus) => {
    const statusStylesMapping = {
      PENDING: { backgroundColor: "#FFB3000D", color: "#FFB300" },
      APPROVED: { backgroundColor: "#24D46D0D", color: "#24D46D" },
      REJECTED: { backgroundColor: "#EE20200D", color: "#EE2020" },
      CANCELLED: { backgroundColor: "#213A571A", color: "#213A57" },
    };
    return statusStylesMapping[status];
  };

  return (
    <div id="LeaveRecord-main" className="h-full w-full">
      {handleDeleteModal()}
      <header id="LeaveRecord-header" className="flex w-full items-center gap-6">
        <SearchInput inputChange={(value) => setDebouncedSearchValue(value)} />
        <RoundButton
          text={t("common.filter")}
          onClick={() => {}}
          height="48px"
          py="3"
          px="6"
          gap="12px"
          iconRight={<SVGIcon icon={FilterIcon} fill="#ffffff" width="24" height="24" />}
          borderRadius="12px"
          fontWeight="600"
        />
        <RoundButton
          text={t("leave.create_leave_record")}
          onClick={() => navigate(`${location.pathname}/create-leave-record`)}
          height="48px"
          py="3"
          px="6"
          gap="12px"
          iconLeft={<SVGIcon icon={CircleArrowRightIcon} fill="#030623" width="24" height="24" />}
          borderRadius="12px"
          bgColor="bg-secondary"
          textColor="text-primary"
          fontWeight="600"
        />
      </header>
      <main id="main table" className="relative mt-6 overflow-x-auto">
        {isLeaveRecordListLoading && <LoadingSpinner />}
        <Table theme={tableTheme}>
          <Table.Head className="h-[72px]">
            {tableHeadCells.map((tableHeadCell, index) => {
              return (
                <Table.HeadCell key={index} className="bg-primary text-base font-medium text-white">
                  <div className="flex items-center gap-2">
                    <p>{t(`leave.${tableHeadCell}`)}</p>
                  </div>
                </Table.HeadCell>
              );
            })}
          </Table.Head>
          <Table.Body className="divide-y border-b border-b-[#71707180]">
            {leaveRecordListData?.data?.map((leaveRecord: LeaveListItem) => (
              <React.Fragment key={leaveRecord.id}>
                <Table.Row className="text-dark h-[83px] bg-white text-sm font-medium">
                  <Table.Cell id="employee_name" className="whitespace-nowrap">
                    {leaveRecord.employee.name}
                  </Table.Cell>
                  <Table.Cell id="leave_type">
                    <Badge
                      className="flex h-9 w-fit items-center justify-center rounded-[3px] px-1.5 py-3 text-sm font-semibold"
                      style={handleLeaveTypeBadgeStyles(leaveRecord.type)}
                    >
                      {t(`leave.${leaveRecord.type}`)}
                    </Badge>
                  </Table.Cell>
                  <Table.Cell id="start_date">{formatDateFromHyphenToSlash(leaveRecord.startDate)}</Table.Cell>
                  <Table.Cell id="end_date">{formatDateFromHyphenToSlash(leaveRecord.endDate)}</Table.Cell>
                  <Table.Cell id="number_of_days">{leaveRecord.numOfDay}</Table.Cell>
                  <Table.Cell id="approval_status">
                    <Badge
                      className="flex h-9 w-fit items-center justify-center rounded-[3px] px-1.5 py-3 text-sm font-semibold"
                      style={handleApprovalStatusBadgeStyles(leaveRecord.status)}
                    >
                      {t(`leave.${leaveRecord.status}`)}
                    </Badge>
                  </Table.Cell>
                  <Table.Cell id="create_time">{formatDateTimeBySlash(leaveRecord.createdAt)}</Table.Cell>
                  <Table.Cell id="action">
                    <div className="flex gap-3">
                      <Badge className="badge-purple">{t("common.view_details")}</Badge>
                      <Badge
                        className="badge-red"
                        onClick={() => handleDeleteState(leaveRecord.id, leaveRecord.employee.name)}
                      >
                        {t("common.delete")}
                      </Badge>
                    </div>
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        </Table>
      </main>
      <footer className="w-full">
        {leaveRecordListData && (
          <Pagination
            currentPage={currentPage}
            totalPages={leaveRecordListData.totalPages}
            totalCount={leaveRecordListData.totalCount}
            currentLength={leaveRecordListData.data.length}
            onPageChange={(page: number) => setCurrentPage(page)}
            onLimitChange={(limit: number) => setCurrentLimit(limit)}
          />
        )}
      </footer>
    </div>
  );
};
export default LeaveRecord;
