import { useMutation, useQuery } from "@tanstack/react-query";
import { SortColumn } from "@/types/employee";
import { SortOrder } from "@/types/util";
import { login } from "@/api";

export const useLogin = () => {
  return useMutation({
    mutationFn: (credentials: { username: string; password: string }) =>
      login(credentials.username, credentials.password).then((res: any) => res.data),
  });
};
