type Props = {
  size?: number;
  message: string | [];
};

const ErrorMessage = ({ size, message }: Props) => {
  return (
    <div className="w-full text-red-500">
      {Array.isArray(message) ? (
        <div className="flex w-full flex-col gap-1">
          {message.map((msg, index) => (
            <p key={index} style={{ fontSize: size ? `${size}px` : "14px" }}>
              {msg}
            </p>
          ))}
        </div>
      ) : (
        <p style={{ fontSize: size ? `${size}px` : "14px" }}>{message}</p>
      )}
    </div>
  );
};

export default ErrorMessage;
