// UI Components
import TitleButton from "@/components/buttons/TitleButton";
import RoundButton from "@/components/buttons/RoundButton";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { FloatingLabel } from "flowbite-react";
import ConfirmationModal from "@/components/modals/ConfirmationModal";
import SingleSelectDropdown from "@/components/dropdowns/SingleSelectDropdown";

// React and Routing
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

// Icons
import { ReactComponent as SaveIcon } from "@/assets/icons/svg/saveIcon.svg";
import { ReactComponent as AddItemsIcon } from "@/assets/icons/svg/addItemsIcon.svg";
import { ReactComponent as BinIcon } from "@/assets/icons/svg/binIcon.svg";

// Types and Themes
import { floatingLabelTheme } from "@/flowbiteThemes/floatingLabelTheme";
import { Cycle } from "@/types/enums";
import { CommissionItem, CreateScheme } from "@/types/scheme";
import { useCreateCommissionScheme, useGetCommissionSchemeList } from "@/api/scheme/commissionScheme.store";
import MultiCheckSearchDropdown from "@/components/dropdowns/MultiCheckSearchDropdown";
import { handleError } from "@/utils/functions";
import ErrorMessage from "@/components/utils/ErrorMessage";
import { showToast } from "@/components/toasts/toast";

const feCycleData = [Cycle.MONTHLY, Cycle.QUARTERLY, Cycle.HALF_YEARLY, Cycle.YEARLY];

const CreateCommissionScheme = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  // initial create scheme body
  const initialCreateSchemeBody: CreateScheme = {
    code: null,
    name: null,
    cycle: null,
    clientName: null,
    items: [],
    childSchemes: [],
  };

  // handle input change
  const [createSchemeBody, setCreateSchemeBody] = useState<CreateScheme>(initialCreateSchemeBody);
  const handleInputChange = (key: keyof CreateScheme, value: string | null) => {
    setCreateSchemeBody((prev) => ({ ...prev, [key]: value }));
  };

  // commission ranges items
  const initialCommissionRangesItem: CommissionItem = {
    min: null,
    max: null,
    percentage: null,
    bonus: null,
  };

  // handle item input change (commission ranges)
  const handleItemInputChange = (index: number, key: keyof CommissionItem, value: number | string | null) => {
    setCreateSchemeBody((prev) => ({
      ...prev,
      items: prev.items.map((item, i) => (i === index ? { ...item, [key]: value } : item)),
    }));
  };

  const onAddItemClick = () => {
    setCreateSchemeBody((prev) => ({ ...prev, items: [...prev.items, initialCommissionRangesItem] }));
  };

  // delete modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [deleteItemIndex, setDeleteItemIndex] = useState<number | null>(null);

  const handleDeleteState = (index: number) => {
    setIsDeleteModalOpen(true);
    setDeleteItemIndex(index);
  };

  // delete item
  const handleDeleteItem = () => {
    setCreateSchemeBody((prev) => ({ ...prev, items: prev.items.filter((_, i) => i !== deleteItemIndex) }));
    setIsDeleteModalOpen(false);
  };

  // delete modal
  const handleDeleteModal = () => {
    if (deleteItemIndex === null) return null;

    const item = createSchemeBody.items[deleteItemIndex];
    if (!item) return null;

    return (
      <ConfirmationModal
        isOpen={isDeleteModalOpen && deleteItemIndex !== null}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setDeleteItemIndex(null);
        }}
        title={t("commission_scheme.delete_item_confirmation")}
      >
        <div className="text-dark w-full">
          <header className="w-full text-base font-semibold">
            {t("commission_scheme.delete_confirmation_message", { item: `${deleteItemIndex + 1}` })}
          </header>
          <article className="mt-6 w-full">
            <p className="text-base font-semibold">{t("common.item_details")}</p>
            <section className="mt-3 flex flex-col gap-3 text-sm font-medium">
              <div className="flex w-full items-center">
                <p className="min-w-[120px]">{`${t("common.min")}(≥):`}</p>
                <p>{item.min ? `$${item.min}` : ""}</p>
              </div>
              <div className="flex w-full items-center">
                <p className="min-w-[120px]">{`${t("common.max")}(<):`}</p>
                <p>{item.max ? `$${item.max}` : ""}</p>
              </div>
              <div className="flex w-full items-center">
                <p className="min-w-[120px]">{`${t("common.percentage")}:`}</p>
                <p>{item.percentage ? `${item.percentage}%` : ""}</p>
              </div>
              <div className="flex w-full items-center">
                <p className="min-w-[120px]">{`${t("common.bonus")}:`}</p>
                <p>{item.bonus ? `$${item.bonus}` : ""}</p>
              </div>
            </section>
          </article>
          <button className="confirm-modal-btn" onClick={handleDeleteItem}>
            {t("common.delete")}
          </button>
        </div>
      </ConfirmationModal>
    );
  };

  // fetch get commission scheme list
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(10);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>("");
  const [cycle, setCycle] = useState<string[]>([]);
  const handleCycleChange = (value: Cycle) => {
    if (value === Cycle.MONTHLY) {
      setCycle([Cycle.QUARTERLY, Cycle.HALF_YEARLY, Cycle.YEARLY]);
    } else {
      setCycle([Cycle.MONTHLY]);
    }
  };
  const {
    data: commissionSchemeListData,
    isLoading: isLoadingCommissionSchemeList,
    isError: isErrorCommissionSchemeList,
    error: errorCommissionSchemeList,
  } = useGetCommissionSchemeList(currentPage - 1, currentLimit, undefined, undefined, debouncedSearchValue, cycle);

  // handle error
  if (isErrorCommissionSchemeList) {
    const errorMessage = handleError(errorCommissionSchemeList);
    showToast.error(errorMessage);
  }

  // handle save
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { mutate: createCommissionScheme, isPending: isPendingCreateCommissionScheme } = useCreateCommissionScheme();
  const handleSave = () => {
    createCommissionScheme(createSchemeBody, {
      onSuccess: () => {
        showToast.success(t("common.success"));
        navigate(`/cms/${params.organisationCode}/commission-scheme`);
      },
      onError: (error) => {
        const errorMessage = handleError(error);
        showToast.error(errorMessage);
        setErrorMessage(errorMessage);
      },
    });
  };

  return (
    <div className="w-full">
      <TitleButton
        title={t("commission_scheme.create_commission_scheme")}
        onClick={() => navigate(`/cms/${params.organisationCode}/commission-scheme`)}
      />
      <main id="CreateCommissionScheme-main" className="mt-6 w-full">
        {handleDeleteModal()}
        <section className="flex w-full items-center gap-3">
          <div className="w-full">
            <FloatingLabel
              variant="filled"
              label={t("commission_scheme.scheme_code")}
              theme={floatingLabelTheme}
              type="text"
              name="code"
              value={createSchemeBody.code ?? ""}
              onChange={(e) => handleInputChange("code", e.target.value)}
            />
          </div>
          <div className="w-full">
            <FloatingLabel
              variant="filled"
              label={t("commission_scheme.scheme_name")}
              theme={floatingLabelTheme}
              type="text"
              name="name"
              value={createSchemeBody.name ?? ""}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
          </div>
          <div className="w-full">
            <SingleSelectDropdown
              translationKey="commission_scheme"
              label="cycle"
              data={feCycleData}
              onSelect={(value) => {
                handleInputChange("cycle", value);
                handleCycleChange(value);
              }}
              selectedValue={createSchemeBody.cycle ?? ""}
              withTranslate
            />
          </div>
        </section>
        <section className="flex w-full items-center gap-3">
          <div className="w-full">
            <FloatingLabel
              variant="filled"
              label={t("client.client_name")}
              theme={floatingLabelTheme}
              type="text"
              name="clientName"
              value={createSchemeBody.clientName ?? ""}
              onChange={(e) => handleInputChange("clientName", e.target.value)}
            />
          </div>
        </section>
        <section id="commission-ranges" className="mt-6 flex w-full items-center justify-between">
          <p className="text-dark text-sm font-semibold">{t(`commission_scheme.commission_ranges`)}</p>
          <RoundButton
            text={t("commission_scheme.add_items")}
            onClick={onAddItemClick}
            height="48px"
            py="3"
            px="6"
            gap="12px"
            iconLeft={<SVGIcon icon={AddItemsIcon} fill="#030623" width="24" height="24" />}
            borderRadius="12px"
            bgColor="bg-secondary"
            textColor="text-primary"
            fontWeight="600"
          />
        </section>

        {createSchemeBody.items.map((item, index) => {
          return (
            <section id="commission-ranges-items" className="mt-6 flex w-full flex-col gap-6" key={index}>
              <header className="flex w-full items-center justify-between">
                <p className="text-dark text-sm font-semibold">{`${t("common.item")} ${index + 1}`}</p>
                <button
                  id="bin-btn"
                  className="hover-scale-sm h-12 items-center justify-center rounded-xl border border-secondary px-6 py-3"
                  onClick={() => handleDeleteState(index)}
                >
                  <SVGIcon icon={BinIcon} fill="#16E6ED" width="24" height="24" />
                </button>
              </header>

              <div className="flex w-full items-center gap-3">
                <div className="w-full">
                  <FloatingLabel
                    variant="filled"
                    label={`${t("common.min")}(≥)`}
                    theme={floatingLabelTheme}
                    type="text"
                    value={item.min ? `$${item.min}` : ""}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, "");
                      handleItemInputChange(index, "min", parseInt(value) || null);
                    }}
                  />
                </div>
                <div className="w-full">
                  <FloatingLabel
                    variant="filled"
                    label={`${t("common.max")}(<)`}
                    theme={floatingLabelTheme}
                    value={item.max ? `$${item.max}` : ""}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, "");
                      handleItemInputChange(index, "max", parseInt(value) || null);
                    }}
                  />
                </div>
                <div className="w-full">
                  <FloatingLabel
                    variant="filled"
                    label={`${t("common.percentage")}`}
                    theme={floatingLabelTheme}
                    value={item.percentage ? `${item.percentage}%` : ""}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, "");
                      handleItemInputChange(index, "percentage", parseInt(value) || null);
                    }}
                  />
                </div>
                <div className="w-full">
                  <FloatingLabel
                    variant="filled"
                    label={`${t("common.bonus")}`}
                    theme={floatingLabelTheme}
                    value={item.bonus ? `$${item.bonus}` : ""}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, "");
                      handleItemInputChange(index, "bonus", parseInt(value) || null);
                    }}
                  />
                </div>
              </div>
            </section>
          );
        })}
        <section id="client" className="mt-6 w-full">
          <p className="text-dark text-sm font-semibold">{t("employee.commission_scheme")}</p>
          <figure className="mt-6 w-full">
            <MultiCheckSearchDropdown
              placeholder={t("employee.select_commission_scheme")}
              searchPlaceholder={t("employee.search_by_commission_scheme")}
              data={commissionSchemeListData?.data ?? []}
              totalCount={commissionSchemeListData?.totalCount ?? 0}
              onSelect={(item) => handleInputChange("childSchemes", item)}
              debouncedSearchValueFn={(value) => setDebouncedSearchValue(value)}
              isLoading={isLoadingCommissionSchemeList}
              loadMoreDataFn={(limit) => setCurrentLimit(limit)}
            />
          </figure>
        </section>
        <div className="mt-6 w-full">
          <ErrorMessage message={errorMessage} />
        </div>
        <section className="mt-[100px] flex w-full justify-end">
          <RoundButton
            text={t("common.save")}
            onClick={handleSave}
            height="48px"
            bgColor="bg-secondary"
            textColor="text-dark"
            fontWeight="600"
            px="6"
            py="3"
            gap="12px"
            borderRadius="12px"
            iconLeft={<SVGIcon icon={SaveIcon} fill="#030623" width="24" height="24" />}
            disabled={isPendingCreateCommissionScheme}
          />
        </section>
      </main>
    </div>
  );
};

export default CreateCommissionScheme;
