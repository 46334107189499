import { api } from "@/api/apiClient";
import { SortColumn } from "@/types/employee";
import { LeaveStatus } from "@/types/enums";
import { LeaveType } from "@/types/enums";
import { CreateLeave, UpdateLeave } from "@/types/leave";
import { SortOrder } from "@/types/util";

export const getLeave = (id: number) => {
  return api.get(`/leave/${id}`);
};

export const getLeaveList = (
  page: number,
  limit: number,
  sortColumn?: SortColumn,
  sortOrder?: SortOrder,
  searchValue?: string,
  type?: LeaveType,
  status?: LeaveStatus,
  startDate?: string,
  endDate?: string,
  createdAt?: string
) => {
  return api.get(`/leave`, {
    params: {
      page,
      limit,
      sortColumn,
      sortOrder,
      searchValue,
      type,
      status,
      startDate,
      endDate,
      createdAt,
    },
  });
};

export const createLeave = (data: CreateLeave) => {
  return api.post(`/leave`, data);
};

export const updateLeave = (id: number, data: UpdateLeave) => {
  return api.put(`/leave/${id}`, data);
};

export const deleteLeave = (id: number) => {
  return api.delete(`/leave/${id}`);
};
