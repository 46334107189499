import { api } from "@/api/apiClient";
import { SortColumn } from "@/types/employee";
import { CreateScheme, UpdateScheme } from "@/types/scheme";
import { SortOrder } from "@/types/util";

export const getScheme = (id: number) => {
  return api.get(`/scheme/${id}`);
};

export const getCommissionSchemeList = (
  page: number,
  limit: number,
  sortColumn?: SortColumn,
  sortOrder?: SortOrder,
  searchValue?: string,
  cycle?: string[]
) => {
  return api.get(`/commission-scheme`, {
    params: {
      page,
      limit,
      sortColumn,
      sortOrder,
      searchValue,
      cycle,
    },
  });
};

export const createScheme = (data: CreateScheme) => {
  return api.post(`/commission-scheme`, data);
};

export const updateScheme = (id: number, data: UpdateScheme) => {
  return api.patch(`/commission-scheme/${id}`, data);
};

export const deleteScheme = (id: number) => {
  return api.delete(`/commission-scheme/${id}`);
};
