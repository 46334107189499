// organisation code
export const setOrganisationCode = (organisationCode: string) => {
  localStorage.setItem("organisationCode", organisationCode);
};

export const getOrganisationCode = (): string => {
  return localStorage.getItem("organisationCode") || "";
};

export const removeOrganisationCode = () => {
  localStorage.removeItem("organisationCode");
};

// token
export const setToken = (token: string) => {
  localStorage.setItem("token", token);
};

export const getToken = (): string => {
  return localStorage.getItem("token") || "";
};

export const removeToken = () => {
  localStorage.removeItem("token");
};

// lang
export const setLang = (lang: string) => {
  localStorage.setItem("lang", lang);
};

export const getLang = (): string => {
  return localStorage.getItem("lang") || "";
};

export const removeLang = () => {
  localStorage.removeItem("lang");
};
