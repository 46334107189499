import { useEffect, useState } from "react";
import { Dropdown } from "flowbite-react";
import { ReactComponent as DropdownIcon } from "@/assets/icons/svg/dropdownIcon.svg";
import { ReactComponent as ArrowLeft } from "@/assets/icons/svg/arrows/arrowLeft.svg";
import { ReactComponent as ArrowLeftEnd } from "@/assets/icons/svg/arrows/arrowLeftEnd.svg";
import { ReactComponent as ArrowRight } from "@/assets/icons/svg/arrows/arrowRight.svg";
import { ReactComponent as ArrowRightEnd } from "@/assets/icons/svg/arrows/arrowRightEnd.svg";
import SVGIcon from "../SVGIcons/SVGIcon";
import { useTranslation } from "react-i18next";

type Props = {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  currentLength: number;
  onPageChange: (page: number) => void;
  onLimitChange: (limit: number) => void;
};

type PageChangeAction = "previousEnd" | "previous" | "next" | "nextEnd";

const Pagination = ({ currentPage, totalPages, totalCount, currentLength, onPageChange, onLimitChange }: Props) => {
  const { t } = useTranslation();

  //page change by action
  const handlePageChange = (action: PageChangeAction) => {
    const actionMapping: Record<string, number> = {
      previousEnd: 1,
      previous: currentPage - 1,
      next: currentPage + 1,
      nextEnd: totalPages,
    };
    onPageChange(actionMapping[action]);
  };

  //result per page
  const resultsPerPage = [10, 20, 50, 100];
  const [selectedResultPerPage, setSelectedResultPerPage] = useState<number>(10);
  const handleResultPerPage = (limit: number) => {
    setSelectedResultPerPage(limit);
    onLimitChange(limit);
  };

  //scroll to top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    scrollToTop();
  }, [currentPage]);

  //custom trigger
  const customTrigger = () => {
    return (
      <div
        id="pagination-dropdown"
        className="hover-scale-sm flex h-9 w-[59px] cursor-pointer items-center gap-[6px] p-2"
      >
        <p className="font-medium text-[#212121]">{selectedResultPerPage}</p>
        <SVGIcon icon={DropdownIcon} fill="#212121" width="20" height="20" />
      </div>
    );
  };

  // handle number of showing result
  const numberOfShowingResult = () => {
    const startResult = (currentPage - 1) * selectedResultPerPage + 1;
    const endResult = Math.min(currentPage * selectedResultPerPage, totalCount);
    return `${startResult}-${endResult}`;
  };

  return (
    <div className="flex w-full items-center justify-between py-9">
      <section id="pagination-result-control" className="text-grey flex items-center gap-3 text-sm">
        <p className="font-normal">{`${numberOfShowingResult()} ${t("pagination.of")} ${totalCount}`}</p>
        <p className="font-normal">{t("pagination.result_per_page")}</p>
        <figure className="flex items-center justify-center rounded-md shadow">
          <Dropdown label="" dismissOnClick={true} renderTrigger={customTrigger} theme={dropdownTheme}>
            {resultsPerPage.map((resultQuantity, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleResultPerPage(resultQuantity)}
                  className="font-medium text-[#212121]"
                >
                  {resultQuantity}
                </Dropdown.Item>
              );
            })}
          </Dropdown>
        </figure>
      </section>
      <section id="pagination-nav" className="flex h-9 min-w-[225px] max-w-[280px] items-center gap-[6px]">
        <figure
          className={currentPage === 1 ? button_disabled : button_activated}
          onClick={() => {
            currentPage !== 1 && handlePageChange("previousEnd");
          }}
        >
          <SVGIcon icon={ArrowLeftEnd} fill="#717071" width="24" height="24" />
        </figure>
        <figure
          className={currentPage === 1 ? button_disabled : button_activated}
          onClick={() => {
            currentPage !== 1 && handlePageChange("previous");
          }}
        >
          <SVGIcon icon={ArrowLeft} fill="#717071" width="24" height="24" />
        </figure>
        <div className="mx-[6px] w-fit text-center text-sm font-medium text-[#212121]">{`${currentPage} ${t("pagination.of")} ${totalPages}`}</div>
        <figure
          className={currentPage === totalPages ? button_disabled : button_activated}
          onClick={() => {
            currentPage !== totalPages && handlePageChange("next");
          }}
        >
          <SVGIcon icon={ArrowRight} fill="#717071" width="24" height="24" />
        </figure>
        <figure
          className={currentPage === totalPages ? button_disabled : button_activated}
          onClick={() => {
            currentPage !== totalPages && handlePageChange("nextEnd");
          }}
        >
          <SVGIcon icon={ArrowRightEnd} fill="#717071" width="24" height="24" />
        </figure>
      </section>
    </div>
  );
};

export default Pagination;

const button_disabled = "w-9 h-9 flex justify-center items-center rounded-[36px] shadow opacity-50";
const button_activated = "w-9 h-9 flex justify-center items-center rounded-[36px] shadow cursor-pointer hover-scale-md";

const dropdownTheme = {
  content: "py-1 focus:outline-none",
  floating: {
    animation: "transition-opacity",
    base: "z-10 w-fit divide-y divide-gray-100 rounded shadow focus:outline-none",
    content: "py-1 text-sm text-gray-700",
    divider: "my-1 h-px bg-gray-100",
    header: "block px-4 py-2 text-sm text-gray-700",
    hidden: "invisible opacity-0",
    item: {
      container: "",
      base: "flex w-full cursor-pointer items-center justify-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none",
      icon: "mr-2 h-4 w-4",
    },
    style: {
      dark: "bg-gray-900 text-white",
      light: "border border-gray-200 bg-white text-gray-900",
      auto: "border border-gray-200 bg-white text-gray-900",
    },
    target: "w-fit",
  },
  inlineWrapper: "flex items-center",
};
