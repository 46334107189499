import { useEffect } from "react";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { showToast } from "@/components/toasts/toast";

type Props = {
  message: string;
};

// Usage:
// ERROR: showToast.error(e.response.data.message);
// SUCCESS: showToast.success(message);
// NAVIGATE SUCCESS: navigate(`/cms/${params.organisationCode}/users?status=success`);

const NotificationToast = ({ message }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const notifySuccess = () => {
    showToast.success(message);
  };

  const notifyError = () => {
    showToast.error(message);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get("status");

    if (status === "success") {
      notifySuccess();
    }
    if (status === "error") {
      notifyError();
    }
    // const timer = setTimeout(() => {
    //   navigate(location.pathname, { replace: true });
    // }, 3000);

    // return () => clearTimeout(timer);
  }, [location.search, navigate, location.pathname]);

  return (
    <>
      <ToastContainer style={{ width: "fit-content" }} bodyStyle={{ padding: "18px" }} />
    </>
  );
};

export default NotificationToast;
