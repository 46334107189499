import { useMutation, useQuery } from "@tanstack/react-query";
import { SortColumn } from "@/types/employee";
import { SortOrder } from "@/types/util";
import { createLeave, deleteLeave, getLeaveList, updateLeave } from "./leave.api";
import { CreateLeave, UpdateLeave } from "@/types/leave";
import { LeaveType } from "@/types/enums";
import { LeaveStatus } from "@/types/enums";

// create leave
export const useCreateLeave = () => {
  return useMutation({
    mutationFn: (data: CreateLeave) => createLeave(data),
  });
};

// update leave
export const useUpdateLeave = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: UpdateLeave }) => updateLeave(id, data),
  });
};

// get leave list
export const useGetLeaveList = (
  page: number,
  limit: number,
  sortColumn?: SortColumn,
  sortOrder?: SortOrder,
  searchValue?: string,
  type?: LeaveType,
  status?: LeaveStatus,
  startDate?: string,
  endDate?: string,
  createdAt?: string
) => {
  return useQuery({
    queryKey: [
      "leaves",
      { page, limit, sortColumn, sortOrder, searchValue, type, status, startDate, endDate, createdAt },
    ],
    queryFn: () =>
      getLeaveList(
        page,
        limit,
        sortColumn,
        sortOrder,
        searchValue ?? "",
        type,
        status,
        startDate ?? "",
        endDate ?? "",
        createdAt ?? ""
      ).then((res) => res.data),
  });
};

// delete leave
export const useDeleteLeave = () => {
  return useMutation({
    mutationFn: (id: number) => deleteLeave(id),
  });
};
