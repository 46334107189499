// External imports
import { Route, Routes } from "react-router-dom";

// Styles
import "./App.css";

// Pages
import PageNotFound from "./pages/PageNotFound";

// Routes
import CmsRoutes from "./routes/cms/CmsRoutes";

// Components
import NotificationToast from "./components/toasts/NotificationToast";

function App() {
  return (
    <>
      <NotificationToast message="" />
      <Routes>
        <Route path="/cms/*" element={<CmsRoutes />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
