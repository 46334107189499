// Components
import TitleButton from "@/components/buttons/TitleButton";
import RoundButton from "@/components/buttons/RoundButton";
import SVGIcon from "@/components/SVGIcons/SVGIcon";
import { FloatingLabel } from "flowbite-react";
import SingleSelectDropdown from "@/components/dropdowns/SingleSelectDropdown";
import FloatingDatePicker from "@/components/pickers/FloatingDatePicker";
import ErrorMessage from "@/components/utils/ErrorMessage";

// React and Routing
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

// Icons
import { ReactComponent as SaveIcon } from "@/assets/icons/svg/saveIcon.svg";

// Types and Themes
import { floatingLabelTheme } from "@/flowbiteThemes/floatingLabelTheme";
import { HolidayType } from "@/types/enums";
import { CreateHoliday } from "@/types/holiday";

// API and Utils
import { useCreateHoliday } from "@/api/holiday/holiday.store";
import { showToast } from "@/components/toasts/toast";
import { handleError } from "@/utils/functions";

const feHolidayTypeData = [HolidayType.STATUTORY, HolidayType.COMPANY, HolidayType.OTHER];

const CreateHolidayRecord = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  // initial create holiday record body
  const initialCreateHolidayRecordBody: CreateHoliday = {
    name: null,
    type: null,
    startDate: null,
    endDate: null,
  };

  // handle input change
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [createHolidayRecordBody, setCreateHolidayRecordBody] = useState<CreateHoliday>(initialCreateHolidayRecordBody);
  const handleInputChange = (key: keyof CreateHoliday, value: string | null) => {
    setCreateHolidayRecordBody((prev) => ({ ...prev, [key]: value }));
  };

  // handle save
  const { mutate: createHolidayRecord, isPending: isPendingCreateHolidayRecord } = useCreateHoliday();
  const handleSave = () => {
    createHolidayRecord(createHolidayRecordBody, {
      onSuccess: () => {
        showToast.success(t("common.success"));
        navigate(`/cms/${params.organisationCode}/holiday`);
      },
      onError: (error) => {
        const errorMessage = handleError(error);
        showToast.error(errorMessage);
        setErrorMessage(errorMessage);
      },
    });
  };

  return (
    <div className="w-full">
      <TitleButton
        title={t("holiday.create_holiday_record")}
        onClick={() => navigate(`/cms/${params.organisationCode}/holiday`)}
      />
      <header className="mt-6 flex w-full items-center justify-between text-sm font-semibold">
        <p className="text-dark">{t("holiday.holiday_information")}</p>
        <button>
          <RoundButton
            text={t("common.save")}
            onClick={handleSave}
            height="48px"
            bgColor="bg-secondary"
            textColor="text-dark"
            fontWeight="600"
            px="6"
            py="3"
            gap="12px"
            borderRadius="12px"
            iconLeft={<SVGIcon icon={SaveIcon} fill="#030623" width="24" height="24" />}
            disabled={isPendingCreateHolidayRecord}
          />
        </button>
      </header>
      <main id="CreateHolidayRecord-main" className="mt-6 w-full">
        <article id="holiday-details-container">
          <section className="flex w-full items-center gap-3">
            <div className="w-full">
              <FloatingLabel
                variant="filled"
                label={t("holiday.holiday_name")}
                theme={floatingLabelTheme}
                onChange={(e) => handleInputChange("name", e.target.value)}
                value={createHolidayRecordBody.name || ""}
              />
            </div>
            <SingleSelectDropdown
              translationKey="holiday"
              label="holiday_type"
              data={feHolidayTypeData}
              onSelect={(value) => {
                handleInputChange("type", value);
              }}
              selectedValue={createHolidayRecordBody.type || ""}
              withTranslate
            />
          </section>

          <section className="mt-6 flex w-full items-center gap-3">
            <FloatingDatePicker
              label={t("holiday.start_date")}
              value={createHolidayRecordBody.startDate || ""}
              onChange={(value) => handleInputChange("startDate", value)}
              position="left"
            />
            <FloatingDatePicker
              label={t("holiday.end_date")}
              value={createHolidayRecordBody.endDate || ""}
              onChange={(value) => handleInputChange("endDate", value)}
            />
          </section>
          <section className="mt-6 w-full">
            <ErrorMessage message={errorMessage} />
          </section>
        </article>
      </main>
    </div>
  );
};

export default CreateHolidayRecord;
