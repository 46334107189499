import React, { useEffect, useRef, useState } from "react";
import SVGIcon from "../SVGIcons/SVGIcon";
import { ReactComponent as TriangleArrowDownIcon } from "@/assets/icons/svg/arrows/triangleArrow/triangleArrowDownIcon.svg";
import { ReactComponent as TriangleArrowUpIcon } from "@/assets/icons/svg/arrows/triangleArrow/triangleArrowUpIcon.svg";
import { ReactComponent as SearchMagnifierIcon } from "@/assets/icons/svg/searchMagnifierIcon.svg";
import { ReactComponent as CrossIcon } from "@/assets/icons/svg/crossIcon.svg";
import { Label } from "flowbite-react";
import { Checkbox } from "flowbite-react";
import { checkboxTheme } from "@/flowbiteThemes/checkboxTheme";
import { useTranslation } from "react-i18next";
import { HiOutlineX } from "react-icons/hi";

type Props = {
  placeholder: string;
  searchPlaceholder: string;
  data: any;
  totalCount: number;
  onSelect: (item: any) => void;
  debouncedSearchValueFn: (value: string) => void;
  loadMoreDataFn?: (limit: number) => void;
  isLoading?: boolean;
};

const MultiCheckSearchDropdown = ({
  placeholder,
  searchPlaceholder,
  data,
  totalCount,
  onSelect,
  debouncedSearchValueFn,
  loadMoreDataFn,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  // Debounce the search input by 300ms
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      debouncedSearchValueFn(searchValue);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  // load more
  const [limit, setLimit] = useState(20);
  const loadMoreData = () => {
    setLimit((prev) => prev + 20);
    loadMoreDataFn && loadMoreDataFn(limit + 20);
  };

  // handle selection
  const [selectedNameWithId, setSelectedNameWithId] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState<any[]>([]);

  const handleSelection = (item: any) => {
    // handle selected name
    setSelectedNameWithId((prev) => {
      const exist = prev.some((s) => s.id === item.id);
      let updatedName;
      if (exist) {
        updatedName = prev.filter((s) => s.id !== item.id);
      } else {
        updatedName = [...prev, { id: item.id, name: item.name }];
      }
      return updatedName;
    });
    // handle selected data with id
    setSelectedData((prevData) => {
      const exists = prevData.some((s) => s.id === item.id);
      if (exists) {
        return prevData.filter((s) => s.id !== item.id);
      } else {
        return [...prevData, { id: item.id }];
      }
    });
  };

  // Function to check if id is selected (checkbox)
  const isSelected = (item: any) => {
    return selectedData.some((s) => s.id === item.id);
  };

  // handle onSelect
  useEffect(() => {
    onSelect(selectedData);
  }, [selectedData]);

  // handle click-outside
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      className={`${isOpen && "border-secondary border"} relative w-full cursor-pointer rounded-lg bg-white p-3 shadow`}
      onClick={() => setIsOpen(true)}
    >
      <section className="flex w-full items-center">
        <figure className="w-full">
          {selectedNameWithId.length > 0 ? (
            <div id="selected-label-container" className="flex w-full flex-wrap items-center gap-1.5">
              {selectedNameWithId?.map((item, index) => (
                <figure
                  key={index}
                  id="selected-label"
                  className="text-dark hover-scale-sm flex items-center justify-between gap-1.5 rounded-[3px] bg-[#0306230D] p-1.5 text-[10px] font-semibold"
                >
                  <p className="w-full">{item.name}</p>
                  <figure
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelection(item);
                    }}
                  >
                    <HiOutlineX className="h-3 w-3 text-[#5F6368]" />
                  </figure>
                </figure>
              ))}
            </div>
          ) : (
            <p className="text-sm font-medium text-[#717071B3]">{placeholder}</p>
          )}
        </figure>
        <figure
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
        >
          <SVGIcon icon={isOpen ? TriangleArrowUpIcon : TriangleArrowDownIcon} fill="#717071" width="24" height="24" />
        </figure>
      </section>
      {isOpen && (
        <section
          ref={dropdownRef}
          className="animate-fade-in-down absolute left-0 top-[110%] z-10 w-full rounded-lg bg-white p-3 shadow"
        >
          <section className="flex w-full items-center gap-3 border-b-[1px] border-[#BDBDBD80] px-3 pb-3 pt-1.5">
            <SVGIcon icon={SearchMagnifierIcon} fill="#717071" width="24" height="24" />
            <input
              placeholder={searchPlaceholder}
              className="w-full text-sm font-medium  placeholder:text-[#717071B3] focus:outline-none"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <figure
              className="hover-scale-sm cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(false);
              }}
            >
              <SVGIcon icon={CrossIcon} fill="#BDBDBD" width="24" height="24" />
            </figure>
          </section>

          <section id="checkbox-container" className="mt-3 max-h-[400px] min-h-fit overflow-scroll">
            {data?.length > 0 ? (
              data?.map((item: any) => (
                <figure
                  key={item.id}
                  id="checkbox"
                  className="flex w-full items-center gap-3 rounded-lg px-3 hover:bg-[#0306230D]"
                >
                  <Checkbox
                    id={`checkbox-${item.id}`}
                    theme={checkboxTheme}
                    onChange={() => handleSelection(item)}
                    checked={isSelected(item)}
                  />
                  <Label
                    htmlFor={`checkbox-${item.id}`}
                    className="text-dark w-full cursor-pointer py-1.5 text-sm font-medium"
                  >
                    {item.name}
                  </Label>
                </figure>
              ))
            ) : (
              <p className="text-center text-sm font-medium">
                {isLoading ? `${t("common.loading")}...` : t("common.no_results_found")}
              </p>
            )}
            {totalCount > limit && (
              <button
                id="load-more-btn"
                className="bg-secondary flex h-9 w-full items-center justify-center rounded-lg text-sm text-white"
                onClick={loadMoreData}
              >
                <p>{t("common.more")}</p>
              </button>
            )}
          </section>
        </section>
      )}
    </div>
  );
};

export default MultiCheckSearchDropdown;
